import {
    Container,
    FormControl,
    Input,
    InputGroup,
    InputLeftElement,
    Radio,
    RadioGroup,
    Stack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { IoSearch } from "react-icons/io5";

export default function ManageSubscriptionFeature() {
    const camelCaseStyle = {
        textTransform: 'capitalize',
        whiteSpace: 'nowrap', // Prevents breaking words
    };

    return (
        <Container maxWidth='9xl'>
            <Text className='custom' fontSize={{ base: 'xs', md: "lg" }} fontWeight="500" color="primary.800">
                Subscription Management &gt;{' '}
                <Text as="span" color="black" pl={2}>
                    Manage Subscription Features
                </Text>
            </Text>

            <VStack alignItems={'start'} mt={8} mb={4} mx={{ base: 6, md: 0 }} spacing={6}>

                <FormControl w={{ base: '100%', md: '50%', lg: '35%' }}>
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <IoSearch size={'18px'} color='#F51E9A' />
                        </InputLeftElement>
                        <Input
                            id="search"
                            name="search"
                            type="text"
                            variant="filled"
                            required={true}
                            placeholder="Search here..."
                            fontSize={{ base: 'xs', md: 'sm' }}
                            py={4}
                            px={10}
                            _focus={{
                                borderColor: "primary.800",
                            }}
                            borderRadius="10"
                        />
                    </InputGroup>
                </FormControl>

                <TableContainer w={'100%'} borderRadius="md" style={{ boxShadow: '0 0 1px rgba(0, 0, 0, 0.2)' }} mt={4}>
                    <Table variant="simple" >
                        <Thead>
                            <Tr bg="primary.100">
                                <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center" fontSize={'sm'}>ID</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center" fontSize={'sm'}>Feature Name</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center" fontSize={'sm'}>Level</Th>
                            </Tr>
                        </Thead>
                        <Tbody
                            sx={{ 'tr:not(:last-of-type)': { borderBottom: '2px solid', borderColor: 'gray.200' } }}
                        >
                            {[...Array(3)].map((_, index) => (
                                <Tr key={index} fontSize="sm">
                                    <Td textAlign="center">#123</Td>
                                    <Td textAlign="center">Chat</Td>
                                    <td textAlign="center">
                                        <RadioGroup defaultValue='2'>
                                            <Stack spacing={5} direction='row' justifyContent={'center'}>
                                                <Radio colorScheme='pink' value='1'>
                                                    Free
                                                </Radio>
                                                <Radio colorScheme='pink' value='2'>
                                                    Premium
                                                </Radio>
                                            </Stack>
                                        </RadioGroup>
                                    </td>
                                </Tr>
                            ))}

                        </Tbody>
                    </Table>
                </TableContainer>
            </VStack>
        </Container>
    )
}

