import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    Button,
    VStack,
    ModalHeader,
    ModalCloseButton,
    Input,
    Textarea,
    FormControl,
    Container,
    Text,
} from '@chakra-ui/react';
import { useFormik } from "formik";
import { useRef, useState } from 'react';
import { RiImageAddFill } from "react-icons/ri";

export default function CreateNewNotification({ isOpen, onClose, onOpen }) {

    const fileInputRef = useRef(null);
    const [selectedImage, setSelectedImage] = useState(null);

    const formik = useFormik({
        initialValues: {
            title: "",
            description: "",
        },
        onSubmit: async (values) => {
            console.log(values);
        }
    });

    const handleContainerClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered onOpen size={{ base: 'sm', md: '2xl' }}>
                <ModalOverlay />
                <ModalContent mx={{ base: 4, md: 0 }}>
                    <ModalHeader fontSize={'md'} color={'white'} bg='primary.400' w='100%'>
                        Create New Notification
                    </ModalHeader>
                    <ModalCloseButton color={'white'} />

                    <form onSubmit={formik.handleSubmit}>
                        <VStack w={'auto'} alignItems={'start'} mx={4} mt={6}>

                            <FormControl>
                                <Input
                                    id="title"
                                    name="title"
                                    type="text"
                                    variant="filled"
                                    required={true}
                                    placeholder="Title"
                                    fontSize="sm"
                                    py={4}
                                    bg="none"
                                    borderColor="gray.100"
                                    _focus={{
                                        borderColor: "primary.800",
                                    }}
                                    borderRadius="10"
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                />
                            </FormControl>

                            <FormControl>
                                <Textarea
                                    id="description"
                                    name="description"
                                    type="text"
                                    variant="filled"
                                    required={true}
                                    placeholder="Description"
                                    fontSize="sm"
                                    py={4}
                                    bg="none"
                                    borderColor="gray.100"
                                    _focus={{
                                        borderColor: "primary.800",
                                    }}
                                    borderRadius="10"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                />
                            </FormControl>

                            <Container
                                w={'100%'}
                                bg='primary.50'
                                mt={4}
                                borderRadius={'lg'}
                                borderStyle="dashed"
                                borderWidth="1px"
                                borderColor="primary.800"
                                onClick={handleContainerClick}
                                cursor="pointer"
                                position="relative" // Added for positioning the image
                            >
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                    accept="image/*"
                                />
                                <VStack my={8} color='#F51E9A' spacing={1}>
                                    <RiImageAddFill size={25} />
                                    <Text fontSize={'xs'}>Upload Your Image Here...</Text>
                                    <Text fontSize={'xs'} fontWeight={'300'} color='#ABABAB'>(Max File Size 50MB)</Text>
                                </VStack>
                                {selectedImage && (
                                    <img
                                        src={selectedImage}
                                        alt="Selected"
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            maxWidth: '100%',
                                            maxHeight: '100%',
                                            borderRadius: 'lg',
                                        }}
                                    />
                                )}
                            </Container>

                            {/* <Container
                                w={'100%'}
                                bg='primary.50'
                                mt={4}
                                borderRadius={'lg'}
                                borderStyle="dashed"
                                borderWidth="1px"
                                borderColor="primary.800"
                            >

                                <VStack my={8} color='#F51E9A' spacing={1}>
                                    <RiImageAddFill size={25} />
                                    <Text fontSize={'xs'}>Upload Your Image Here...</Text>
                                    <Text fontSize={'xs'} fontWeight={'300'} color='#ABABAB'>(Max File Size 50MB)</Text>
                                </VStack>

                            </Container> */}

                            <ModalFooter w={'100%'} px={16}>
                                <Button
                                    my={4}
                                    type="submit"
                                    bg="secondary"
                                    py='6'
                                    width="full"
                                    borderRadius={'50'}
                                    fontSize={'sm'}
                                >
                                    Create Notification
                                </Button>
                            </ModalFooter>

                        </VStack>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}


