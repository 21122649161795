import {
    Box,
    Button,
    Container,
    HStack,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    SimpleGrid,
    Stack,
    Text,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IoMdArrowDropdown } from "react-icons/io";

export default function MatchesDetails() {
    const { state } = useLocation();
    const camelCaseStyle = {
        textTransform: 'capitalize',
        whiteSpace: 'nowrap',
    };

    return (
        <Container maxWidth='9xl' px={6}>
            <Stack direction={{ base: 'column', md: 'row' }} w={'100%'} justifyContent={'space-between'}>
                <Text className='custom' fontSize={{ base: 'xs', md: "lg" }} fontWeight="500" color="primary.800">
                    <Link to='/user-management'>
                        User Management &gt;{' '}
                    </Link>
                    <Text as="span" color="black" pl={2}>
                        {state.first_name}
                    </Text>
                    <Text as="span" color="black" pl={2}>
                        {state.last_name}
                    </Text>
                </Text>

                {/* <Menu>
                    <MenuButton>
                        <Box
                            mt={{ base: 4, md: 0 }}
                            px={4}
                            py={2}
                            border={'1px'}
                            borderRadius={'md'}
                            borderColor={'#F2F2F2'}
                        >
                            <HStack justifyContent={{ base: 'space-between' }}>
                                <Text fontSize={'sm'}>More Options</Text>
                                <IoMdArrowDropdown color='#F51E9A' size={25} />
                            </HStack>
                        </Box>
                    </MenuButton>
                    <MenuList >
                        <MenuItem minH='30px'>
                            <Link to={'/#'}>
                                <Text fontSize={'sm'} pl={1}>Resume Account</Text>
                            </Link>
                        </MenuItem>

                        <MenuItem minH='30px'>
                            <Link to={'/#'}>
                                <Text fontSize={'sm'} pl={1}>Suspend Account</Text>
                            </Link>
                        </MenuItem>

                        <MenuItem minH='30px'>
                            <Link to={'/#'}>
                                <Text pl={1} fontSize={'sm'} color={'red'}>Delete Account</Text>
                            </Link>
                        </MenuItem>
                    </MenuList>
                </Menu> */}
            </Stack>

            <Box
                mt={4}
                border="1px"
                borderColor="#F2F2F2"
                borderRadius="md"
                mb={12}
                w={{ base: '100%', lg: '80%' }}
                py={{ base: 4, md: 0 }}
            >
                <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 3 }}
                    alignItems="center"
                    spacing={{ base: 6, md: 20 }}
                >

                    <Link to={'/user-management/user-details'} state={state}>
                        <Text textAlign={'center'}>
                            User Details
                        </Text>
                    </Link>

                    <Link to={'/user-management/preference-details'} state={state}>
                        <Text textAlign={'center'}>
                            Preference Details
                        </Text>
                    </Link>

                    <Button
                        py={{ base: 6, md: 8 }}
                        type="submit"
                        bg="primary.800"
                        width="full"
                        borderRadius="12"
                        fontSize="sm"
                        color="white"
                    >
                        Matches
                    </Button>

                </SimpleGrid>
            </Box>

            <TableContainer w={{ base: '100%', lg: '80%' }} borderRadius="md" style={{ boxShadow: '0 0 1px rgba(0, 0, 0, 0.2)' }}>
                <Table variant="simple" >
                    <Thead>
                        <Tr bg="primary.100">
                            <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">Date</Th>
                            <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">Name</Th>
                            <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">Job Title</Th>
                            <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">Match Percentage</Th>
                        </Tr>
                    </Thead>
                    <Tbody
                        sx={{ 'tr:not(:last-of-type)': { borderBottom: '2px solid', borderColor: 'gray.200' } }}
                    >
                        {[...Array(5)].map((_, index) => (
                            <Tr key={index} fontSize="sm">
                                <Td textAlign="center">Jan 01, 2022</Td>
                                <Td textAlign="center">Jane Doe</Td>
                                <Td textAlign="center">Professional Model</Td>
                                <Td textAlign="center">100%</Td>
                            </Tr>
                        ))}

                    </Tbody>
                </Table>
            </TableContainer>
        </Container>
    )
}
