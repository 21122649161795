import {
    Button,
    Center,
    Container,
    HStack,
    Switch,
    Text,
    VStack
} from '@chakra-ui/react';
import React from 'react';

export default function ViewSubscription() {
    return (
        <Container maxWidth='9xl'>
            <Text className='custom' fontSize={{ base: 'xs', md: "lg" }} fontWeight="500" color="primary.800">
                Subscription Management &gt;{' '}
                <Text as="span" >
                    Manage Your Plans
                </Text>
                <Text as="span" color="black" pl={2}>
                    &gt;{' '} View Subscription
                </Text>
            </Text>

            <VStack w={'100%'} alignItems={'start'} mt={8} spacing={6}>

                <VStack alignItems={'start'} spacing={1}>
                    <Text fontSize={'md'} fontWeight={'600'}>Subscription Plan Name</Text>
                    <Text fontSize={'md'} color={'grey'} fontWeight={'500'}>Plan 1</Text>
                </VStack>

                <VStack alignItems={'start'} spacing={1}>
                    <Text fontSize={'md'} fontWeight={'600'}>Original Price</Text>
                    <Text fontSize={'md'} color={'grey'} fontWeight={'500'}>50</Text>
                </VStack>

                <VStack alignItems={'start'} spacing={1}>
                    <Text fontSize={'md'} fontWeight={'600'}>Discounted Price</Text>
                    <Text fontSize={'md'} color={'grey'} fontWeight={'500'}>50</Text>
                </VStack>

                <VStack alignItems={'start'} spacing={1}>
                    <Text fontSize={'md'} fontWeight={'600'}>Features</Text>
                    <Text fontSize={'sm'} color={'grey'} fontWeight={'500'}>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.Lorem Ipsum has been the industry's standard dummy text.
                    </Text>
                </VStack>

                <HStack mt={2}>
                    <Switch colorScheme='pink' size={'lg'} id='isChecked' isChecked />
                    <Text fontSize={'sm'} color={'green'}>Active</Text>
                </HStack>

                <Container w={'100%'}>
                    <Center>
                        <Button
                            my={4}
                            type="submit"
                            bg="primary.800"
                            py='6'
                            width={{ base: 'full', md: '180px' }}
                            borderRadius={'12'}
                            fontSize={'sm'}
                            color={'white'}
                        >
                            Edit
                        </Button>
                    </Center>
                </Container>
            </VStack>
        </Container>
    )
}

