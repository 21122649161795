import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { url } from "../config";
import { toast } from "react-toastify";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    // State to hold the authentication token
    const [token, setToken_] = useState();
    const [logged, setLogged] = useState(false);

    // Function to set the authentication token
    const setToken = (newToken) => {
        setToken_(newToken);
        if (newToken == null) {
            localStorage.removeItem("kjlemon_admin_token");
        } else {
            localStorage.setItem("kjlemon_admin_token", newToken);
        }
    };

    async function loginOtp(email, password) {
        console.log("LOGIN OTP");
        try {
            await axios.post(`${url}/admin/login-otp`, {
                "email": email,
                "password": password,
            });
            toast.success("Otp sent successfully");
        } catch (error) {
            throw Object.assign(
                new Error(error),
                { code: 500 }
            );
        }
    }

    async function login(email, otp) {
        console.log("LOGIN");
        setLoading(true);

        try {
            var { data } = await axios.post(`${url}/admin/login`, {
                "email": email,
                "otp": otp,
            });
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            setLogged(true);
            setToken(data.token);
            setLoading(false);
            setUser(data.user);
            localStorage.setItem("user", JSON.stringify(data.user));
        } catch (error) {
            console.log(error);
            throw Object.assign(
                new Error(error),
                { code: 500 }
            );
        }
    }

    // Call the logout endpoint and then remove the user
    // from the state.
    function logout() {
        setLogged(false);
        setLoading(false);
        setToken(null);
    }

    async function updateLocaluser(user) {
        localStorage.setItem("user", JSON.stringify(user));
        setUser(user);
    }

    useEffect(() => {
        let tkn = localStorage.getItem("kjlemon_admin_token");
        if (tkn !== null && tkn !== "null") {
            setLogged(true);
            setLoading(false);
            setToken(tkn);

            // set user too
            setUser(JSON.parse(localStorage.getItem("user")));
        } else {
            setLogged(false);
            setLoading(false);
            setToken(null);
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            loading,
            logged,
            token,
            user,
            login,
            setToken,
            updateLocaluser,
            logout,
            loginOtp,
        }),
        [token, logged, user] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider;