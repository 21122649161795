import { Card, Container, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

export default function Settings() {
    return (
        <Container maxWidth='9xl'>
            <Text className='custom' fontSize="lg" fontWeight="500">Settings</Text>

            <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing={4}
                alignItems={{ base: 'center', md: 'start' }}
            >
                <Card mt='5' bg='primary.200' py={14} w={{ base: '70%', md: '20%' }} alignItems={'center'}>
                    <Link to={'/settings/notification-list'}>
                        <Text className='custom' fontSize='md' color='primary.900'>Push Notifications</Text>
                    </Link>
                </Card>

                <Card mt='5' bg='primary.200' py={14} w={{ base: '70%', md: '20%' }} alignItems={'center'}>
                    <Link to={'/settings/your-profile'}>
                        <Text className='custom' fontSize='md' color='primary.900'>Your Profile</Text>
                    </Link>
                </Card>

            </Stack>
        </Container>
    )
}
