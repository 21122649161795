import {
    Button,
    Card,
    Center,
    Container,
    Flex,
    HStack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { RiUserAddLine } from "react-icons/ri";
import { FaStar, FaRegStar } from "react-icons/fa";
import { useRatings } from "../../../providers/RatingsProvider";

export default function Ratings() {
    const { rating, totalRating, currentPage, totalPages, getRating } = useRatings();

    const camelCaseStyle = {
        textTransform: 'capitalize',
        whiteSpace: 'nowrap', // Prevents breaking words
    };

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns month from 0 to 11
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    // Function to generate stars based on rating
    const generateStars = (rating) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            stars.push(i <= rating ? <FaStar key={i} size={20} color='orange' /> : <FaRegStar key={i} size={20} color='grey' />);
        }
        return stars;
    };

    useEffect(() => {
        console.log(currentPage)
        console.log(totalPages)
    })

    return (
        <Container maxWidth='9xl'>
            <Card mt='5' bg='primary.200' pl={4} py={6} w={{ base: '85%', md: '25%', lg: '15%' }} mx={{ base: 6, md: 0 }}>
                <VStack spacing={0} alignItems={'start'}>
                    <RiUserAddLine size={25} color='#F51E9A' />
                    <Text className='custom' fontSize='2xl' color='primary.800'>{totalRating}</Text>
                    <Text fontSize={'xs'}>Total Reviews</Text>
                </VStack>
            </Card>

            <VStack alignItems={'start'} mt={8} mb={4} mx={{ base: 6, md: 0 }} spacing={4}>
                <TableContainer w={'100%'} borderRadius="md" style={{ boxShadow: '0 0 1px rgba(0, 0, 0, 0.2)' }}>
                    <Table variant="simple">
                        <Thead>
                            <Tr bg="primary.100">
                                {/* <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">Sr No</Th> */}
                                <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">Review By</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">Review</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">Date</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">Given Rating</Th>
                            </Tr>
                        </Thead>
                        <Tbody
                            sx={{ 'tr:not(:last-of-type)': { borderBottom: '2px solid', borderColor: 'gray.200' } }}
                        >
                            {rating.map((r, index) => {
                                const formattedJoinedDate = formatDate(r.createdAt);
                                return (
                                    <Tr key={index} fontSize="sm">
                                        {/* <Td textAlign="center">{index + 1}</Td> */}
                                        <Td textAlign="center">{r.user ? r.user.username : 'Anonymous'}</Td>
                                        <Td textAlign="center" whiteSpace="normal" wordWrap="break-word">{r.feedback}</Td>
                                        <Td textAlign="center">{formattedJoinedDate}</Td>
                                        <Td textAlign="center" color={'#F51E9A'}>
                                            <HStack justifyContent="center">
                                                {generateStars(r.rating)}
                                            </HStack>
                                        </Td>
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </VStack>

            {/* / Pagination/ */}
            <HStack w={'100%'} alignItems={'center'} justifyContent="center">
                <Center>
                    <Flex>
                        {/* {currentPage == 1 || currentPage == 0 ? null :
                            <Button
                                colorScheme='primary'
                                variant='outline'
                                onClick={() => getRating(currentPage - 1)}
                                ml={2}
                            >Previous</Button>
                        } */}
                        {[...Array(totalPages)].map((_, i) =>
                            <Button
                                key={i}
                                colorScheme={currentPage == i + 1 ? "primary" : "gray"}
                                onClick={() => getRating(i + 1)}
                                ml={2}
                            >{i + 1}</Button>
                        )}
                        {/* {(totalPages == currentPage || totalPages == 0) ? null :
                            <Button
                                colorScheme='primary'
                                variant='outline'
                                onClick={() => getRating(currentPage + 1)}
                                ml={2}
                            >Next</Button>
                        } */}
                    </Flex>
                </Center>
            </HStack>

        </Container>
    );
}
