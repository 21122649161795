import {
    SimpleGrid,
    Box,
    Card,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import SendToGroups from './SendToGroups';
import SendToSpecificUsers from './SendToSpecificUsers';

export default function PushNotification() {
    // export default function PushNotification({ isOpen, onClose }) {

    const { isOpen: isSendToGroupOpen, onOpen: onSendToGroupOpen, onClose: onSendToGroupClose } = useDisclosure();
    const { isOpen: isSendToSpecificUsersOpen, onOpen: onSendToSpecificUsersOpen, onClose: onSendToSpecificUsersClose } = useDisclosure();

    return (
        <>

            <SimpleGrid columns={[1, 2, 2, 3]} spacing='4'>
                <Box w='100%' alignItems='center'>
                    <Card bg='primary.200' p='12' w='100%' alignItems={'center'} h='100%'>
                        <Text className='custom' fontSize='md' color='primary.900'>Send to All Users</Text>
                    </Card>

                </Box>
                <Box cursor='pointer' onClick={() => onSendToGroupOpen()} w='100%' alignItems='center'>
                    <Card bg='primary.200' p='12' w='100%' alignItems={'center'} h='100%'>
                        <Text className='custom' fontSize='md' color='primary.900'> Send To Groups</Text>
                    </Card>

                </Box>
                <Box cursor='pointer' onClick={() => onSendToSpecificUsersOpen()} w='100%' alignItems='center'>
                    <Card bg='primary.200' p='12' w='100%' alignItems={'center'} h='100%'>
                        <Text className='custom' fontSize='md' color='primary.900'> Send To Specific Users</Text>
                    </Card>
                </Box>
            </SimpleGrid >

            {/* <Modal isOpen={isOpen} onClose={onClose} isCentered onOpen size={'sm'}>
                <ModalOverlay />
                <ModalContent mx={{ base: 4, md: 0 }}>
                    <ModalCloseButton />
                    <VStack spacing={4} mt={12} mb={6} mx={12}>
                        <Button
                            type="submit"
                            bg="primary.800"
                            color={'white'}
                            fontWeight={'400'}
                            py='4'
                            width="full"
                            borderRadius={'12'}
                            fontSize={'sm'}
                        >
                            Send To All Users
                        </Button>

                        <Button
                            type="submit"
                            borderColor="primary.800"
                            color='primary.800'
                            fontWeight={'400'}
                            py='4'
                            width="full"
                            borderRadius={'12'}
                            fontSize={'sm'}
                            variant='outline'
                            onClick={() => onSendToGroupOpen()}
                        >
                            Send To Groups
                        </Button>

                        <Button
                            type="submit"
                            borderColor="primary.800"
                            color='primary.800'
                            fontWeight={'400'}
                            py='4'
                            width="full"
                            borderRadius={'12'}
                            fontSize={'sm'}
                            variant='outline'
                            onClick={() => onSendToSpecificUsersOpen()}
                        >
                            Send To Specific Users
                        </Button>

                    </VStack>
                </ModalContent>

            </Modal> */}
            <SendToGroups isOpen={isSendToGroupOpen} onClose={onSendToGroupClose} />
            <SendToSpecificUsers isOpen={isSendToSpecificUsersOpen} onClose={onSendToSpecificUsersClose} />
        </>
    )
}


