import {
    Card,
    Container,
    HStack,
    Text,
    SimpleGrid,
    VStack,
    Image,
    Stack
} from '@chakra-ui/react';
import React from 'react';
import { FaSquare } from "react-icons/fa";
import { Link } from 'react-router-dom';

export default function Dashboard() {

    return (
        <>
            <Container maxWidth='9xl'>

                <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 3 }}
                    spacing={4}
                >
                    <Link to={'/user-management'}>
                    <Card mt='5'>
                        <HStack width='100%' justifyContent='space-between' p='5'>
                            <VStack w={'100%'} alignItems={'start'}>
                                <Image src='/user_management.png' />
                                <Text className='custom' fontSize='xl' color='primary.900'>User Management</Text>
                            </VStack>
                        </HStack>
                    </Card>
                    </Link>

                    <Link to={'/subscription-management'}>
                    <Card mt='5'>
                        <HStack width='100%' justifyContent='space-between' p='5'>
                            <VStack w={'100%'} alignItems={'start'}>
                                <Image src='/subscription_plan.png' />
                                <Text className='custom' fontSize='xl' color='primary.900'>Subscription Plan</Text>
                            </VStack>
                        </HStack>
                    </Card>
                    </Link>
                    <Link to={'/virtual-gifts'}>
                    <Card mt='5'>
                        <HStack width='100%' justifyContent='space-between' p='5'>
                            <VStack w={'100%'} alignItems={'start'}>
                                <Image src='/virtual_gifts.png' />
                                <Text className='custom' fontSize='xl' color='primary.900'>Virtual Gifts</Text>
                            </VStack>
                        </HStack>
                    </Card>
                    </Link>

                </SimpleGrid>

                <Card mt={8}>
                    <VStack>
                        <Stack
                            w={'100%'}
                            direction={{ base: 'column', md: 'column', lg: 'row' }}
                            justifyContent={'space-between'}
                            px={8}
                        >
                            <VStack alignItems={'start'} spacing={0}>
                                <Text fontWeight='800' fontSize='2xl'>Reports & Analytics</Text>
                                <Text fontWeight='400' fontSize='md' color='grey.200'>No of users enrolled and got matched in 2024</Text>
                            </VStack>

                            <HStack spacing={4} mt={{ base: 6, md: 6, lg: 0 }}>
                                <HStack>
                                    <FaSquare color='#F51E9A' />
                                    <Text>New users</Text>
                                </HStack>
                                <HStack>

                                    <FaSquare color='#FFBEE3' />
                                    <Text>Matched users</Text>
                                </HStack>
                            </HStack>
                        </Stack>

                        <Image src='/bar_graph.png' mt={8} />
                    </VStack>
                </Card>

            </Container>
        </>
    )
}
