import {
    Card,
    Container,
    HStack,
    Stack,
    Text,
    VStack,
    useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { FiPlusCircle } from "react-icons/fi";
import { Link } from 'react-router-dom';
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegDotCircle } from "react-icons/fa";
import CreateFaq from '../../models/CreateFaq';
import UpdateFaq from '../../models/UpdateFaq';
import { useFaqs } from "../../../providers/FaqProvider";
import { ConfirmDialog } from '../../utils/ConfirmDialog';

export default function Faq() {
    const { isOpen: isFaqOpen, onOpen: onFaqOpen, onClose: onFaqClose } = useDisclosure();
    // const { isOpen: isEditFaqOpen, onOpen: onEditFaqOpen, onClose: onEditFaqClose } = useDisclosure();

    const [selectedFaq, setSelectedFaq] = useState(null);
    const [isEditFaqOpen, setIsEditFaqOpen] = useState(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(-1);

    const onEditFaqOpen = (faq) => {
        setSelectedFaq(faq);
        setIsEditFaqOpen(true);
    };

    const onEditFaqClose = () => {
        setSelectedFaq(null);
        setIsEditFaqOpen(false);
    };

    const handleConfirm = async () => {
        try {
            await deleteFaq(selectedId);
            setIsConfirmDialogOpen(false);
        } catch (error) {

        }
    };
    const handleClose = () => {
        setIsConfirmDialogOpen(false);
    };

    const { faqs, deleteFaq } = useFaqs();

    return (
        <Container maxWidth='9xl' px={6}>
            <Stack
                direction={{ base: 'column', md: 'row' }}
                mt='5'
                w={'100%'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Text className='custom' fontSize="lg" fontWeight="500" color="primary.800">
                    <Link to='/content-management'>
                        Content Management &gt;{' '}
                    </Link>
                    <Text as="span" color="black" pl={2}>
                        FAQ
                    </Text>
                </Text>

                <Link onClick={() => onFaqOpen()}>
                    <HStack
                        p={5}
                        bg={'secondary'}
                        borderRadius={{ base: '22px', md: '22px', lg: '42px' }}
                        width={{ base: '100%', md: 'auto' }}
                        mt={{ base: 4, md: 0 }}
                        justifyContent={'center'}
                    >

                        <FiPlusCircle size={'22px'} />

                        <Text fontSize={'sm'} fontWeight={'600'}>
                            Create New FAQ
                        </Text>
                    </HStack>
                </Link>
            </Stack>

            <VStack>
                {/* {[...Array(3)].map((_, index) => ( */}
                {faqs.map((faq, index) => (
                    <VStack key={index} spacing={0} w={'100%'}>
                        <VStack mt={6} w={'100%'} alignItems={'end'}>
                            <Card py={2} px={6}>
                                <HStack spacing={6}>
                                    <Link onClick={() => onEditFaqOpen(faq)}>
                                        <HStack spacing={1}>
                                            <MdOutlineEdit />
                                            <Text fontSize={'sm'} fontWeight={'400'}>Edit</Text>
                                        </HStack>
                                    </Link>

                                    <Link onClick={() => { setSelectedId(faq._id); setIsConfirmDialogOpen(true); }}>
                                        <HStack color={'red'} spacing={1}>
                                            <RiDeleteBin6Line />
                                            <Text fontSize={'sm'} fontWeight={'400'}>Delete</Text>
                                        </HStack>
                                    </Link>
                                </HStack>
                            </Card>
                        </VStack>

                        <Card p={4} w={'100%'}>
                            <HStack alignItems={'start'}>
                                <FaRegDotCircle color='#F51E9A' size={18} />
                                <VStack alignItems={'start'} spacing={1} w={{ md: '85%' }}>
                                    <Text fontSize={'sm'} className='custom' color='primary.800'>{faq.title}</Text>
                                    <Text fontSize={'xs'}>
                                        {faq.content}
                                    </Text>
                                </VStack>
                            </HStack>
                        </Card>
                    </VStack>

                ))}
            </VStack>

            <ConfirmDialog
                isOpen={isConfirmDialogOpen}
                onClose={handleClose}
                onConfirm={handleConfirm}
                title="Are you sure?"
                body="This action cannot be undone."
            />

            <CreateFaq isOpen={isFaqOpen} onClose={onFaqClose} />

            {isEditFaqOpen && (
                <UpdateFaq
                    isOpen={isEditFaqOpen}
                    onClose={onEditFaqClose}
                    faq={selectedFaq}
                />
            )}
            {/* <UpdateFaq isOpen={isEditFaqOpen} onClose={onEditFaqClose} /> */}
        </Container>

    )
}
