import {
    Button,
    Container,
    FormControl,
    FormLabel,
    HStack,
    Input,
    Switch,
    Text,
    Textarea,
    VStack
} from '@chakra-ui/react';
import React from 'react';
import { useFormik } from "formik";
import { Link, useLocation } from 'react-router-dom';
import { useVirtualGift } from "../../../providers/VirtualGiftProvider";

export default function EditVirtualGift() {

    const { editVirtualGift } = useVirtualGift();
    const { state } = useLocation();

    const formik = useFormik({
        initialValues: {
            v_gift_name: state.gift_name || "",
            price: state.price || "",
            description: state.description || "",
            is_active: state.is_active || false,
        },
        onSubmit: async (values) => {
            console.log(values);
            await editVirtualGift(state._id, values.v_gift_name, values.price, values.description, values.is_active);
        }
    });

    return (
        <Container maxWidth='9xl'>
            <Text className='custom' fontSize={{ base: 'xs', md: "lg" }} fontWeight="500" color="primary.800">
                <Link to='/virtual-gifts'>
                    Virtual Gifts &gt;{' '}
                </Link>

                <Link to='/virtual-gifts/manage-your-gifts'>
                    <Text as="span" >Manage Your Gifts </Text>
                </Link>

                <Text as="span" color="black" pl={2}>
                    &gt;{' '} Edit Virtual Gift
                </Text>
            </Text>

            <form onSubmit={formik.handleSubmit}>
                <VStack w={{ base: '100%', md: '70%' }} alignItems={'start'} mt={8} spacing={4}>
                    <FormControl >
                        <FormLabel fontSize={'sm'}>Virtual Gift Name</FormLabel>
                        <Input
                            id="v_gift_name"
                            name="v_gift_name"
                            type="text"
                            variant="filled"
                            fontSize="sm"
                            py={4}
                            px={5}
                            bg="none"
                            borderColor="gray.100"
                            _focus={{
                                borderColor: "primary.800",
                            }}
                            borderRadius="10"
                            value={formik.values.v_gift_name}
                            onChange={formik.handleChange}
                        />
                    </FormControl>

                    <FormControl >
                        <FormLabel fontSize={'sm'}>Price</FormLabel>
                        <Input
                            id="price"
                            name="price"
                            type="text"
                            variant="filled"
                            fontSize="sm"
                            py={4}
                            px={5}
                            bg="none"
                            borderColor="gray.100"
                            _focus={{
                                borderColor: "primary.800",
                            }}
                            borderRadius="10"
                            value={formik.values.price}
                            onChange={formik.handleChange}
                        />
                    </FormControl>

                    <FormControl >
                        <FormLabel fontSize={'sm'}>Description</FormLabel>
                        <Textarea
                            id="description"
                            name="description"
                            type="text"
                            variant="filled"
                            fontSize="sm"
                            py={4}
                            px={5}
                            bg="none"
                            borderColor="gray.100"
                            _focus={{
                                borderColor: "primary.800",
                            }}
                            borderRadius="10"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                        />
                    </FormControl>

                    <HStack mt={2}>
                        <Switch
                            colorScheme='pink'
                            size={'lg'}
                            id='is_active'
                            isChecked={formik.values.is_active}
                            onChange={(e) => formik.setFieldValue('is_active', e.target.checked)}
                        />
                        <Text fontSize={'sm'} color={formik.values.is_active ? 'green' : 'red'}>
                            {formik.values.is_active ? 'Active' : 'In-Active'}
                        </Text>
                    </HStack>

                    <Container w={'100%'} justifyItems={'center'}>
                        <HStack mx={{ md: '44px' }}>
                            <Button
                                my={4}
                                type="submit"
                                bg="primary.800"
                                py='6'
                                width="full"
                                borderRadius={'12'}
                                fontSize={'sm'}
                                color={'white'}
                            >
                                Submit
                            </Button>

                            <Button
                                my={4}
                                type="reset"
                                border={'2px'}
                                borderColor="primary.800"
                                color="primary.800"
                                py='6'
                                width="full"
                                borderRadius={'12'}
                                fontSize={'sm'}
                                bg={'none'}
                                onClick={formik.handleReset}
                            >
                                Reset
                            </Button>
                        </HStack>
                    </Container>
                </VStack>
            </form>
        </Container>
    );
}
