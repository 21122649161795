import {
    Modal,
    ModalOverlay,
    ModalContent,
    VStack,
    ModalHeader,
    ModalCloseButton,
    Text,
    HStack,
    Card,
} from '@chakra-ui/react';

export default function ReferralsViewList({ isOpen, onClose }) {

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered onOpen size={'lg'} >
                <ModalOverlay />
                <ModalContent mx={{ base: 4, md: 0 }}>

                    <ModalHeader fontSize={'md'} color={'white'} bg='primary.400' w='100%'>
                        View List
                    </ModalHeader>

                    <ModalCloseButton color={'white'} />

                    <VStack spacing={2} maxH="50vh" overflowY="auto" py={4} px={8}>
                        {[...Array(5)].map((_, index) => (
                            <Card p={2} w={'100%'}>
                                <HStack key={index} w={'100%'} px={8}>
                                <img src='../user_management.png' width={'35px'} alt='user' />

                                <VStack ml={1} spacing={0} alignItems={'start'}>
                                    <Text fontSize={'sm'}>John Doe</Text>
                                    <Text fontSize={'xs'} color={'grey'}>john@gmail.com</Text>
                                </VStack>
                            </HStack>
                            </Card>
                        ))}
                    </VStack>
                </ModalContent>
            </Modal>
        </>
    )
}




