import { Card, Container, Stack, Text, Box } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

export default function ContentManagement() {
    return (
        <Container maxWidth='9xl'>
            <Text className='custom' fontSize="xl" fontWeight="500">Content Management</Text>

            <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing={4}
                alignItems={{ base: 'center', md: 'start' }}
                h='100%'
                w='100%'
            >
                <Box w={{ base: '70%', md: '20%' }} alignItems='center'>
                    <Link to={'/content-management/faq'}>
                        <Card mt='5' bg='primary.200' py={10} w='100%' alignItems={'center'} h='100%'>
                            <Text className='custom' fontSize='md' color='primary.900'>FAQ</Text>
                        </Card>
                    </Link>
                </Box>

                {/* <Card mt='5' bg='primary.200' py={10} px='4' w={{ base: '70%', md: '20%' }} alignItems={'center'} h='100%'>
                    <Link to={'/content-management/about-section'}>
                        <Text className='custom' fontSize='md' color='primary.900'>About Section</Text>
                    </Link>
                </Card>

                <Card mt='5' bg='primary.200' py={10} px='4' w={{ base: '70%', md: '25%' }} alignItems={'center'} h='100%'>
                    <Link to={'/content-management/terms-and-condition'}>
                        <Text className='custom' fontSize='md' color='primary.900'>Terms & Condition</Text>
                    </Link>
                </Card>

                <Card mt='5' bg='primary.200' py={10} px='4' w={{ base: '70%', md: '20%' }} alignItems={'center'} h='100%'>
                    <Link to={'/content-management/privacy-policy'}>
                        <Text className='custom' fontSize='md' color='primary.900'>Privacy Policy</Text>
                    </Link>
                </Card> */}

            </Stack>
        </Container>
    )
}

