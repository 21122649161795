import {
    Modal,
    ModalContent,
    VStack,
    ModalHeader,
    ModalCloseButton,
    Input,
    InputGroup,
    InputLeftElement,
    FormControl,
    Text,
    HStack,
    Checkbox,
    Container,
    Center,
    ModalBody,
    ModalFooter,
    Button,
    ModalOverlay,
    Image,
} from '@chakra-ui/react';
import { IoSearch } from "react-icons/io5";
import { IoChevronBackOutline } from "react-icons/io5";
import { useNotificationGroup } from '../../../providers/NotificationGroupProvider';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

export default function AddMoreUsers({ isOpen, onClose, group }) {
    const { activeUsers, addUserToGroup, searchUser } = useNotificationGroup();

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const handleSelectUser = (userId) => {
        setSelectedUsers((prevSelected) => {
            if (prevSelected.includes(userId)) {
                return prevSelected.filter((id) => id !== userId);
            } else {
                return [...prevSelected, userId];
            }
        });
    };

    const handleSubmit = async () => {
        try {
            if (selectedUsers.length !== 1) {
                return toast.error("Add atleast 1 users");
            }
            await addUserToGroup(group._id, selectedUsers);
            setSelectedUsers([]); // Clear the selected users array
            onClose(); // Close the modal after clearing the selected users
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        searchUser(searchQuery).then(() => {
        });
    }, [searchQuery]);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered size={'lg'}>
                <ModalOverlay />
                <ModalContent mx={{ base: 4, md: 0 }}>
                    <ModalHeader fontSize={'md'} fontWeight={'500'}>
                        <HStack>
                            <IoChevronBackOutline onClick={onClose} />
                            <HStack>
                                <Container ml={{ md: 2 }} bg='primary.800' h={8} w={8} borderRadius={50} alignItems={'center'}>
                                    <Center h="100%">
                                        {group.name ?
                                            <Text fontWeight={'500'} fontSize={'sm'} color={'white'}>
                                                {group.name.charAt(0).toUpperCase()}
                                            </Text>
                                            :
                                            <></>
                                        }
                                    </Center>
                                </Container>
                                <VStack ml={1} spacing={0} alignItems={'start'}>
                                    <Text fontSize={'sm'}>{group.name}</Text>
                                    <Text fontSize={'xs'} color={'grey'}>{group.userCount} users</Text>
                                </VStack>
                            </HStack>
                        </HStack>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody maxH="60vh" px={6}>
                        <VStack spacing={6}>
                            <FormControl w={{ base: '83%', md: '85%' }}>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<IoSearch size={'18px'} />}
                                    />
                                    <Input
                                        id="searchUser"
                                        name="searchUser"
                                        type="text"
                                        variant="filled"
                                        required={true}
                                        placeholder="Search user for adding in group"
                                        fontSize={{ base: 'xs', md: 'sm' }}
                                        py={4}
                                        px={10}
                                        _focus={{
                                            borderColor: "primary.800",
                                        }}
                                        borderRadius="10"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                </InputGroup>
                            </FormControl>

                            <VStack w={'100%'} spacing={6} maxH="40vh" overflowY="auto">
                                {activeUsers.map((u) => (
                                    <HStack key={u._id} w={'100%'} px={8}>
                                        <Checkbox
                                            size='lg'
                                            colorScheme='pink'
                                            mr={2}
                                            isChecked={selectedUsers.includes(u._id)}
                                            onChange={() => handleSelectUser(u._id)}
                                        />
                                        {u.profile_picture ? (
                                            <Image
                                                src={u.profile_picture}
                                                w={'33px'}
                                                h={'33px'}
                                                alt='user'
                                                border={'2px'}
                                                borderColor='primary.800'
                                                borderRadius="50%"
                                                boxShadow={'lg'}
                                            />
                                        ) : (
                                            <Image src='../user_management.png' width={'35px'} alt='user' />
                                        )}
                                        <VStack ml={1} spacing={0} alignItems={'start'}>
                                            <Text fontSize={'sm'}>{u.username}</Text>
                                            <Text fontSize={'xs'} color={'grey'}>{u.email}</Text>
                                        </VStack>
                                    </HStack>
                                ))}
                            </VStack>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            type="submit"
                            bg="secondary"
                            py='4'
                            width="full"
                            borderRadius={'50'}
                            fontSize={'sm'}
                            onClick={handleSubmit}
                        >
                            Add Users
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
