import {
    Button,
    Container,
    FormControl,
    Input,
    InputGroup,
    InputLeftElement,
    SimpleGrid,
    Text,
    VStack
} from '@chakra-ui/react';
import React from 'react';
import { useFormik } from "formik";
import { FaRegUserCircle } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";

export default function CreateNewUser() {
    const formik = useFormik({
        initialValues: {
            f_name: "",
            l_name: "",
            phone: "",
            email: "",
            username: "",
            password: "",
        },
        onSubmit: async (values) => {
            console.log(values);
        }
    });

    return (
        <Container maxWidth='9xl' px={6}>
            <Text mt={4} className='custom' fontSize={{base:'sm', md:'lg'}} fontWeight="500" color="primary.800">
                User Management &gt;{' '}
                <Text as="span" color="black" pl={2}>
                    Create New User
                </Text>
            </Text>

            <VStack>
                <form onSubmit={formik.handleSubmit}>
                    <VStack w={'100%'} alignItems={'start'} mx={4} mt={12}>
                        <SimpleGrid w={'100%'} columns={{ base: 1, lg: 2 }} spacing={4} mt={{md:6}}>
                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<FaRegUserCircle color="#F51E9A" size={'18px'} />}
                                        mt={1}
                                    />
                                    <Input
                                        id="f_name"
                                        name="f_name"
                                        type="text"
                                        variant="filled"
                                        required={true}
                                        placeholder="First Name"
                                        fontSize="sm"
                                        py={6}
                                        px={10}
                                        bg="none"
                                        borderColor="gray.100"
                                        _focus={{
                                            borderColor: "primary.800",
                                        }}
                                        borderRadius="10"
                                        value={formik.values.f_name}
                                        onChange={formik.handleChange}
                                    />
                                </InputGroup>
                            </FormControl>

                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<FaRegUserCircle color="#F51E9A" size={'18px'} />}
                                        mt={1}
                                    />
                                    <Input
                                        id="l_name"
                                        name="l_name"
                                        type="text"
                                        variant="filled"
                                        required={true}
                                        placeholder="Last Name"
                                        fontSize="sm"
                                        py={6}
                                        px={10}
                                        bg="none"
                                        borderColor="gray.100"
                                        _focus={{
                                            borderColor: "primary.800",
                                        }}
                                        borderRadius="10"
                                        value={formik.values.l_name}
                                        onChange={formik.handleChange}
                                    />
                                </InputGroup>
                            </FormControl>

                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<FaRegUserCircle color="#F51E9A" size={'18px'} />}
                                        mt={1}
                                    />
                                    <Input
                                        id="phone"
                                        name="phone"
                                        type="number"
                                        variant="filled"
                                        required={true}
                                        placeholder="Phone No"
                                        fontSize="sm"
                                        py={6}
                                        px={10}
                                        bg="none"
                                        borderColor="gray.100"
                                        _focus={{
                                            borderColor: "primary.800",
                                        }}
                                        borderRadius="10"
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                    />
                                </InputGroup>
                            </FormControl>

                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<IoIosMail color="#F51E9A" size={'18px'} />}
                                        mt={1}
                                    />
                                    <Input
                                        id="email"
                                        name="email"
                                        type="email"
                                        variant="filled"
                                        required={true}
                                        placeholder="Email ID"
                                        fontSize="sm"
                                        py={6}
                                        px={10}
                                        bg="none"
                                        borderColor="gray.100"
                                        _focus={{
                                            borderColor: "primary.800",
                                        }}
                                        borderRadius="10"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                    />
                                </InputGroup>
                            </FormControl>

                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<FaRegUserCircle color="#F51E9A" size={'18px'} />}
                                        mt={1}
                                    />
                                    <Input
                                        id="username"
                                        name="username"
                                        type="text"
                                        variant="filled"
                                        required={true}
                                        placeholder="Username"
                                        fontSize="sm"
                                        py={6}
                                        px={10}
                                        bg="none"
                                        borderColor="gray.100"
                                        _focus={{
                                            borderColor: "primary.800",
                                        }}
                                        borderRadius="10"
                                        value={formik.values.username}
                                        onChange={formik.handleChange}
                                    />
                                </InputGroup>
                            </FormControl>

                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<FaRegUserCircle color="#F51E9A" size={'18px'} />}
                                        mt={1}
                                    />
                                    <Input
                                        id="password"
                                        name="password"
                                        type="password"
                                        variant="filled"
                                        required={true}
                                        placeholder="Password"
                                        fontSize="sm"
                                        py={6}
                                        px={10}
                                        bg="none"
                                        borderColor="gray.100"
                                        _focus={{
                                            borderColor: "primary.800",
                                        }}
                                        borderRadius="10"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                    />
                                </InputGroup>
                            </FormControl>
                        </SimpleGrid>

                        <Container w={{base:'100%', md:'50%'}}>
                            <Button
                                my={4}
                                type="submit"
                                bg="primary.800"
                                py='6'
                                width="full"
                                borderRadius={'12'}
                                fontSize={'sm'}
                                color={'white'}
                            >
                                Create New User
                            </Button>
                        </Container>

                    </VStack>
                </form>
            </VStack>
        </Container>
    )
}
