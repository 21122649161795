import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { url } from "../config";
import { toast } from "react-toastify";
import { useAuth } from "./AuthProvider";

const SubscriptionFeatureContext = createContext();

const SubscriptionFeatureProvider = ({ children }) => {
    const [subscriptionFeatures, setSubscriptionFeature] = useState([]);
    const [loading, setLoading] = useState(true);

    const { token, logged , logout} = useAuth();

    async function editSubscriptionFeature(chat, voice_call, video_call, accept_gifts, give_gifts) {
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            const res = await axios.put(`${url}/admin/subscription-features`, {
                chat, 
                voice_call, 
                video_call, 
                accept_gifts, 
                give_gifts
            }, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            });
            await getSubscriptionFeature();
            toast.success("Updated !");
            return res.data;
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                toast.error(error.response.data.error);
            }
            throw error;
        }
    }

    async function getSubscriptionFeature() {
        console.log("Get Subscription Features");
        setLoading(true);
    
        if (!logged) {
            console.error("User not logged in");
            return;
        }
    
        console.log('Token being sent:', token);
    
        try {
            const { data } = await axios.get(`${url}/admin/subscription-features`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                },
            });
    
            console.log("Received data:", data.subscriptionFeature);
            setSubscriptionFeature(data.subscriptionFeature);
    
        } catch (error) {
            console.error("Get subscription-features error");
            console.error(error);
            if (error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
                // logout();
            }
            return "err";
        }
    }

    useEffect(() => {
        getSubscriptionFeature();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            loading,
            subscriptionFeatures,
            editSubscriptionFeature,
        }),
        [logged, loading, subscriptionFeatures] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Provide the authentication context to the children components
    return (
        <SubscriptionFeatureContext.Provider value={contextValue}>{children}</SubscriptionFeatureContext.Provider>
    );
};

export const useSubscriptionFeatures = () => {
    return useContext(SubscriptionFeatureContext);
};

export default SubscriptionFeatureProvider;
