import {
    Box,
    Button,
    Container,
    FormControl,
    HStack,
    Input,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    SimpleGrid,
    Stack,
    Text,
    FormLabel
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { IoMdArrowDropdown } from "react-icons/io";
import { Link, useLocation } from 'react-router-dom';
import { useFormik } from "formik";

export default function PreferenceDetails() {
    const { state } = useLocation();

    useEffect(() => {
        console.log(state)
    })

    const formik = useFormik({
        initialValues: {
            gender_preference: state.gender_preference || "NA",
            race_preference: state.race_preference || "NA",
            age_range: state.min_age_preference - state.min_age_preference || "NA",
            location_range: state.min_area_preference || "NA",
            reason_use_app: state.what_are_you_looking_for_preference || "NA",
        }
    });

    return (
        <Container maxWidth='9xl' px={6}>
            <Stack direction={{ base: 'column', md: 'row' }} w={'100%'} justifyContent={'space-between'}>
                <Text className='custom' fontSize={{ base: 'xs', md: "lg" }} fontWeight="500" color="primary.800">
                    <Link to='/user-management'>
                        User Management &gt;{' '}
                    </Link>
                    <Text as="span" color="black" pl={2}>
                        {state.first_name}
                    </Text>
                    <Text as="span" color="black" pl={2}>
                        {state.last_name}
                    </Text>
                </Text>

                {/* <Menu>
                    <MenuButton>
                        <Box
                            mt={{ base: 4, md: 0 }}
                            px={4}
                            py={2}
                            border={'1px'}
                            borderRadius={'md'}
                            borderColor={'#F2F2F2'}
                        >
                            <HStack justifyContent={{ base: 'space-between' }}>
                                <Text fontSize={'sm'}>More Options</Text>
                                <IoMdArrowDropdown color='#F51E9A' size={25} />
                            </HStack>
                        </Box>
                    </MenuButton>
                    <MenuList >
                        <MenuItem minH='30px'>
                            <Link to={'/#'}>
                                <Text fontSize={'sm'} pl={1}>Resume Account</Text>
                            </Link>
                        </MenuItem>

                        <MenuItem minH='30px'>
                            <Link to={'/#'}>
                                <Text fontSize={'sm'} pl={1}>Suspend Account</Text>
                            </Link>
                        </MenuItem>

                        <MenuItem minH='30px'>
                            <Link to={'/#'}>
                                <Text pl={1} fontSize={'sm'} color={'red'}>Delete Account</Text>
                            </Link>
                        </MenuItem>
                    </MenuList>
                </Menu> */}
            </Stack>

            <Box
                mt={4}
                border="1px"
                borderColor="#F2F2F2"
                borderRadius="md"
                w={{ base: '100%', lg: '80%' }}
                py={{ base: 4, md: 0 }}
            >
                <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 3 }}
                    alignItems="center"
                    spacing={{ base: 6, md: 20 }}
                >

                    <Link to={'/user-management/user-details'} state = {state}>
                        <Text textAlign={'center'}>
                            User Details
                        </Text>
                    </Link>

                    <Button
                        py={{ base: 6, md: 8 }}
                        type="submit"
                        bg="primary.800"
                        width="full"
                        borderRadius="12"
                        fontSize="sm"
                        color="white"
                    >
                        Preference Details
                    </Button>

                    <Link to={'/user-management/matches-details'} state = {state}>
                        <Text textAlign={'center'}>
                            Matches
                        </Text>
                    </Link>
                </SimpleGrid>
            </Box>

            <SimpleGrid
                w={{ base: '100%', md: '80%' }}
                columns={{ base: 1, lg: 2 }}
                spacing={8}
                mt={12}
                pl={8}
                mb={4}
            >

                <FormControl >
                    <FormLabel fontSize={'sm'}>Gender Preference</FormLabel>
                    <Input
                        type="text"
                        variant="filled"
                        fontSize="sm"
                        py={4}
                        px={10}
                        bg="none"
                        borderColor="gray.100"
                        _focus={{
                            borderColor: "gray.100",
                        }}
                        borderRadius="10"
                        value={formik.values.gender_preference}
                        isReadOnly
                    />
                </FormControl>

                <FormControl >
                    <FormLabel fontSize={'sm'}>Race Preference</FormLabel>
                    <Input
                        type="text"
                        variant="filled"
                        fontSize="sm"
                        py={4}
                        px={10}
                        bg="none"
                        borderColor="gray.100"
                        _focus={{
                            borderColor: "gray.100",
                        }}
                        borderRadius="10"
                        value={formik.values.race_preference}
                        isReadOnly
                    />
                </FormControl>

                <FormControl >
                    <FormLabel fontSize={'sm'}>Age Range</FormLabel>
                    {state.min_age_preference && state.max_age_preference ?
                        <HStack
                            spacing={3}
                            border={'2px'}
                            borderColor="gray.100"
                            borderRadius="10"
                            py={2}
                            px={4}
                        >
                            <Text>{state.min_age_preference}</Text>
                            <Text>-</Text>
                            <Text>{state.max_age_preference}</Text>
                        </HStack>
                        : <HStack
                            spacing={3}
                            border={'2px'}
                            borderColor="gray.100"
                            borderRadius="10"
                            py={2}
                            px={4}
                        ><Text>NA</Text>
                        </HStack>
                    }
                </FormControl>

                <FormControl >
                    <FormLabel fontSize={'sm'}>Location Range</FormLabel>
                    {state.min_area_preference && state.max_area_preference ?
                        <HStack
                            spacing={3}
                            border={'2px'}
                            borderColor="gray.100"
                            borderRadius="10"
                            py={2}
                            px={4}
                        >
                            <Text>{state.min_area_preference}mi</Text>
                            <Text>-</Text>
                            <Text>{state.max_area_preference}mi</Text>
                        </HStack>
                        :
                        <HStack
                            spacing={3}
                            border={'2px'}
                            borderColor="gray.100"
                            borderRadius="10"
                            py={2}
                            px={4}
                        ><Text>NA</Text>
                        </HStack>
                    }
                </FormControl>

                <FormControl >
                    <FormLabel fontSize={'sm'}>reason to use the app</FormLabel>
                    <Input
                        type="text"
                        variant="filled"
                        fontSize="sm"
                        py={4}
                        px={10}
                        bg="none"
                        borderColor="gray.100"
                        _focus={{
                            borderColor: "gray.100",
                        }}
                        borderRadius="10"
                        value={formik.values.reason_use_app}
                        isReadOnly
                    />
                </FormControl>

            </SimpleGrid>
        </Container>
    )
}

