import {
    Button,
    Center,
    Container,
    HStack,
    Switch,
    Text,
    VStack
} from '@chakra-ui/react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function ViewVirtualGift() {
    const { state } = useLocation();

    return (
        <Container maxWidth='9xl'>
            <Text className='custom' fontSize={{ base: 'xs', md: "lg" }} fontWeight="500" color="primary.800">
                <Link to='/virtual-gifts'>
                    Virtual Gifts &gt;{' '}
                </Link>

                <Link to='/virtual-gifts/manage-your-gifts'>
                    <Text as="span" >Manage Your Gifts </Text>
                </Link>

                <Text as="span" color="black" pl={2}>
                    &gt;{' '} View Virtual Gift
                </Text>
            </Text>

            <VStack w={'100%'} alignItems={'start'} mt={8} spacing={6}>

                <VStack alignItems={'start'} spacing={1}>
                    <Text fontSize={'md'} fontWeight={'600'}>Virtual Gift Name</Text>
                    <Text fontSize={'md'} color={'grey'} fontWeight={'500'}>{state.gift_name}</Text>
                </VStack>

                <VStack alignItems={'start'} spacing={1}>
                    <Text fontSize={'md'} fontWeight={'600'}>Price</Text>
                    <Text fontSize={'md'} color={'grey'} fontWeight={'500'}>{state.price}</Text>
                </VStack>

                <VStack alignItems={'start'} spacing={1}>
                    <Text fontSize={'md'} fontWeight={'600'}>Description</Text>
                    <Text fontSize={'sm'} color={'grey'} fontWeight={'500'}>
                        {state.description}
                    </Text>
                </VStack>

                {state.is_active ?
                    <HStack mt={2}>
                        <Switch colorScheme='pink' size={'lg'} id='isChecked' isChecked isDisabled/>
                        <Text fontSize={'sm'} color={'green'}>Active</Text>
                    </HStack>
                    : 
                    <HStack mt={2}>
                    <Switch colorScheme='pink' size={'lg'} isDisabled />
                    <Text fontSize={'sm'} color={'red'}>In Active</Text>
                </HStack>
                }

                {/* <Container w={'100%'}>
                    <Center>
                        <Button
                            my={4}
                            type="submit"
                            bg="primary.800"
                            py='6'
                            width={{ base: 'full', md: '180px' }}
                            borderRadius={'12'}
                            fontSize={'sm'}
                            color={'white'}
                        >
                            Edit
                        </Button>
                    </Center>
                </Container> */}
            </VStack>
        </Container>
    )
}

