import {
    Card,
    Center,
    Container,
    FormControl,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Stack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { RiUserAddLine } from "react-icons/ri";
import { IoSearch } from "react-icons/io5";
import { IoFilter } from "react-icons/io5";
import { RiWallet3Line } from "react-icons/ri";
import { PiDownload } from "react-icons/pi";

export default function SubscriptionPlan() {
    const camelCaseStyle = {
        textTransform: 'capitalize',
        whiteSpace: 'nowrap', // Prevents breaking words
    };

    return (
        <Container maxWidth='9xl'>

            <Stack
                direction={{ base: 'column', md: 'row' }}
                w={'100%'}
            >
                <Stack
                    w={{ md: '70%' }}
                    direction={{ base: 'column', md: 'row' }}
                    spacing={{ base: 2, md: 4 }}
                    alignItems={{ base: 'center', md: 'start' }}
                >
                    <Card mt='5' bg='primary.200' pl={4} py={6} w={{ base: '70%', md: '30%' }}>
                        <VStack spacing={0} alignItems={'start'}>
                            <RiUserAddLine size={28} color='#F51E9A' />
                            <Text className='custom' fontSize='2xl' color='primary.800'>12345</Text>
                            <Text fontSize={'xs'}>Total Subscriptions</Text>
                        </VStack>
                    </Card>

                    <Card mt='5' bg='primary.200' pl={4} py={6} w={{ base: '70%', md: '30%' }}>
                        <VStack spacing={0} alignItems={'start'}>
                            <RiWallet3Line size={28} color='#F51E9A' />
                            <Text className='custom' fontSize='2xl' color='primary.800'>$67543</Text>
                            <Text fontSize={'xs'}>Revenue Generated</Text>
                        </VStack>
                    </Card>
                </Stack>

                {/* <Container w={{ base: '100%', lg: '55%' }} px={{ base: 16, md: 0 }}>
                    <Stack direction={{ base: 'column', lg: 'row' }}>

                        <Link to={'/subscription-management/manage-your-plan'}>
                            <Container
                                p={5}
                                borderRadius={{ base: '22px', md: '22px', lg: '42px' }}
                                border={'2px'}
                                borderColor='primary.800'
                                color='primary.800'
                                width={{ base: '100%', md: 'auto' }}
                                mt={{ base: 4, md: 5 }}
                            >
                                <Text textAlign={'center'} fontSize={'sm'} fontWeight={'500'}>
                                    Manage Your Plans
                                </Text>
                            </Container>
                        </Link>

                        <Link to={'/subscription-management/manage-subscription-feature'}>
                            <Container
                                p={5}
                                bg={'secondary'}
                                borderRadius={{ base: '22px', md: '22px', lg: '42px' }}
                                width={{ base: '100%', md: 'auto' }}
                                mt={{ base: 4, md: 5 }}
                                justifyContent={'center'}
                            >
                                <Text textAlign={'center'} fontSize={'sm'} fontWeight={'500'}>
                                    Manage Subscription Features
                                </Text>
                            </Container>
                        </Link>

                    </Stack>
                </Container> */}

            </Stack>

            <VStack alignItems={'start'} mt={8} mb={4} mx={{ base: 6, md: 0 }} spacing={4}>

                <FormControl w={{ base: '100%', md: '50%', lg: '35%' }}>
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <IoSearch size={'18px'} color='#F51E9A' />
                        </InputLeftElement>
                        <Input
                            id="search"
                            name="search"
                            type="text"
                            variant="filled"
                            required={true}
                            placeholder="Search here..."
                            fontSize={{ base: 'xs', md: 'sm' }}
                            py={4}
                            px={10}
                            _focus={{
                                borderColor: "primary.800",
                            }}
                            borderRadius="10"
                        />
                        <InputRightElement pointerEvents="none">
                            <IoFilter size={'18px'} color='#F51E9A' />
                        </InputRightElement>
                    </InputGroup>
                </FormControl>

                <TableContainer w={'100%'} borderRadius="md" style={{ boxShadow: '0 0 1px rgba(0, 0, 0, 0.2)' }}>
                    <Table variant="simple" >
                        <Thead>
                            <Tr bg="primary.100">
                                <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">ID</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">User Name</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">Date of Subscription</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">Subscription Plan</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">Amount</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">Download Invoice</Th>
                            </Tr>
                        </Thead>
                        <Tbody
                            sx={{ 'tr:not(:last-of-type)': { borderBottom: '2px solid', borderColor: 'gray.200' } }}
                        >
                            {[...Array(5)].map((_, index) => (
                                <Tr key={index} fontSize="sm">
                                    <Td textAlign="center">#123</Td>
                                    <Td textAlign="center">John Doe</Td>
                                    <Td textAlign="center">02/11/1998</Td>
                                    <Td textAlign="center">Basic</Td>
                                    <Td textAlign="center">$50.00/mo</Td>
                                    <Td textAlign="center">
                                        <Center>
                                            <PiDownload size={25} color='#F51E9A' />
                                        </Center>
                                    </Td>
                                </Tr>
                            ))}

                        </Tbody>
                    </Table>
                </TableContainer>
            </VStack>

        </Container>
    )
}
