import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    Button,
    VStack,
    ModalHeader,
    ModalCloseButton,
    Input,
    InputGroup,
    InputLeftElement,
    FormControl,
    Text,
    HStack,
    Checkbox,
    Image,
} from '@chakra-ui/react';
import { IoSearch } from "react-icons/io5";
import { useNotificationGroup } from '../../../providers/NotificationGroupProvider';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export default function CreateNewGroup({ isOpen, onClose }) {
    const { activeUsers, addGroup, searchUser } = useNotificationGroup();

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [groupName, setGroupName] = useState("");
    const [searchQuery, setSearchQuery] = useState("");

    const handleSelectUser = (userId) => {
        setSelectedUsers((prevSelected) => {
            if (prevSelected.includes(userId)) {
                return prevSelected.filter((id) => id !== userId);
            } else {
                return [...prevSelected, userId];
            }
        });
    };

    const handleSubmit = async () => {
        try {
            // const payload = {
            //     name: groupName,
            //     users: selectedUsers
            // };

            if (groupName === "") {
                return toast.error("Enter group name");
            }
            if (selectedUsers.length !== 2) {
                return toast.error("Add atleast 2 users");
            }
            const res = await addGroup(groupName, selectedUsers);
            if (res) {
                setGroupName("");
                setSelectedUsers([]);
                onClose();
            }
            else{
                setGroupName("");
                setSelectedUsers([]); 
            }

        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        searchUser(searchQuery).then(() => {
        });
    }, [searchQuery]);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered size={'md'}>
                <ModalOverlay />
                <ModalContent mx={{ base: 4, md: 0 }}>
                    <ModalHeader fontSize={'md'} fontWeight={'500'} w='100%'>
                        <HStack w={'100%'}>
                            <Text w={'40%'}>Add Group Name</Text>
                            <Input
                                w={'50%'}
                                id="groupName"
                                name="groupName"
                                type="text"
                                variant="filled"
                                required={true}
                                placeholder="Enter Group Name"
                                fontSize={{ base: 'xs', md: 'sm' }}
                                value={groupName}
                                onChange={(e) => setGroupName(e.target.value)}
                                _focus={{
                                    borderColor: "primary.800",
                                }}
                                borderRadius="10"
                            />
                        </HStack>
                    </ModalHeader>
                    <ModalCloseButton />

                    <VStack spacing={6}>
                        <FormControl w={{ base: '83%', md: '85%' }}>
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    children={<IoSearch size={'18px'} />}
                                />
                                <Input
                                    id="searchUser"
                                    name="searchUser"
                                    type="text"
                                    variant="filled"
                                    required={true}
                                    placeholder="Search user for adding in group"
                                    fontSize={{ base: 'xs', md: 'sm' }}
                                    py={4}
                                    px={10}
                                    _focus={{
                                        borderColor: "primary.800",
                                    }}
                                    borderRadius="10"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </InputGroup>
                        </FormControl>

                        <VStack w={'100%'} spacing={6} maxH="40vh" overflowY="auto">
                            {activeUsers.map((u) => (
                                <HStack key={u.id} w={'100%'} px={8}>
                                    <Checkbox
                                        size='lg'
                                        colorScheme='pink'
                                        mr={2}
                                        // isChecked={selectedUsers.includes(u.id)}
                                        onChange={() => handleSelectUser(u._id)}
                                    />
                                    {u.profile_picture ? (
                                        <Image
                                            src={u.profile_picture}
                                            w={'33px'}
                                            h={'33px'}
                                            alt='user'
                                            border={'2px'}
                                            borderColor='primary.800'
                                            borderRadius="50%"
                                            boxShadow={'lg'}
                                        />
                                    ) : (
                                        <Image src='../user_management.png' width={'35px'} alt='user' />
                                    )}
                                    <VStack ml={1} spacing={0} alignItems={'start'}>
                                        <Text fontSize={'sm'}>{u.username}</Text>
                                        <Text fontSize={'xs'} color={'grey'}>{u.email}</Text>
                                    </VStack>
                                </HStack>
                            ))}
                        </VStack>
                    </VStack>

                    <ModalFooter w={'100%'} px={16}>
                        <Button
                            mb={2}
                            type="submit"
                            bg="secondary"
                            py='4'
                            width="full"
                            borderRadius={'50'}
                            fontSize={'sm'}
                            onClick={handleSubmit}
                        >
                            Create Group
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
