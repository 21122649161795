import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    Button,
    VStack,
    ModalHeader,
    ModalCloseButton,
    Input,
    InputGroup,
    InputLeftElement,
    FormControl,
    Text,
    HStack,
    Checkbox,
    Container,
    Center,
    useDisclosure,
    Box,
} from '@chakra-ui/react';
import { FaPlus } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import { Link } from 'react-router-dom';
import CreateNewGroup from './CreateNewGroup';
import ViewList from './ViewList';
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEye } from "react-icons/fi";
import { useNotificationGroup } from '../../../providers/NotificationGroupProvider';
import { useEffect, useState } from 'react';
import { ConfirmDialog } from '../../utils/ConfirmDialog';

export default function SendToGroups({ isOpen, onClose }) {
    const { groups, deleteGroup, getUsersFromGroup, searchGropName } = useNotificationGroup();

    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(-1);
    const [selectedGroupName, setSelectedGroupName] = useState([]); 
    const [searchQuery, setSearchQuery] = useState("");

    const handleConfirm = async () => {
        try {
            await deleteGroup(selectedId);
            setIsConfirmDialogOpen(false);
        } catch (error) {
            console.error(error);
        } finally {
            setIsConfirmDialogOpen(false);
        }
    };
    const handleClose = () => {
        setIsConfirmDialogOpen(false);
    };

    const { isOpen: isCreateNewGroupOpen, onOpen: onCreateNewGroupOpen, onClose: onCreateNewGroupClose } = useDisclosure();
    const { isOpen: isViewListOpen, onOpen: onViewListOpen, onClose: onViewListClose } = useDisclosure();

    useEffect(() => {
        searchGropName(searchQuery).then(() => {
        });
    }, [searchQuery]);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered size={'xl'}>
                <ModalOverlay />
                <ModalContent mx={{ base: 4, md: 0 }}>
                    <ModalHeader fontSize={'md'} fontWeight={'500'} w='100%'>
                        <HStack spacing={{ base: 2, md: 6 }}>
                            <FormControl w={{ base: '53%', md: '55%' }}>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<IoSearch size={'18px'} />}
                                    />
                                    <Input
                                         id="searchUser"
                                         name="searchUser"
                                         type="text"
                                         variant="filled"
                                         required={true}
                                         placeholder="Search group"
                                         fontSize={{ base: 'xs', md: 'sm' }}
                                         py={4}
                                         px={10}
                                         _focus={{
                                             borderColor: "primary.800",
                                         }}
                                         borderRadius="10"
                                         value={searchQuery}
                                         onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                </InputGroup>
                            </FormControl>

                            <HStack color='primary.800' spacing={1}>
                                <FaPlus />
                                <Link onClick={onCreateNewGroupOpen}>
                                    <Text fontSize={{ base: 'xs', md: 'sm' }}> Create New Group</Text>
                                </Link>
                            </HStack>
                        </HStack>
                    </ModalHeader>

                    <ModalCloseButton />

                    <Box maxH="350px" overflowY="auto">
                        <VStack spacing={8}>
                            {groups.map((g, index) => (
                                <HStack w={'100%'} justifyContent={'space-between'} px={8} key={index}>
                                    <HStack>
                                        <Checkbox size='lg' colorScheme='pink' />
                                        <Container ml={2} bg='primary.800' h={8} w={8} borderRadius={50} alignItems={'center'}>
                                            <Center h="100%">
                                                {g.name?
                                                <Text fontWeight={'500'} fontSize={'sm'} color={'white'}>
                                                    {g.name.charAt(0).toUpperCase()}
                                                </Text>
                                                :
                                                <></>
                                                }
                                            </Center>
                                        </Container>
                                        <VStack ml={1} spacing={0} alignItems={'start'}>
                                            <Text fontSize={'sm'}>{g.name}</Text>
                                            <Text fontSize={'xs'} color={'grey'}>{g.userCount} users</Text>
                                        </VStack>
                                    </HStack>
                                    <HStack>
                                        <Link onClick={async () => {
                                            await getUsersFromGroup(g._id);
                                            setSelectedGroupName(g); // Set the selected group data
                                            onViewListOpen(); // Open the ViewList modal
                                        }}>
                                            <FiEye color='#F51E9A' size={18} />
                                        </Link>
                                        <Link onClick={() => { setSelectedId(g._id); setIsConfirmDialogOpen(true); }}>
                                            <RiDeleteBin6Line color='red' size={18} />
                                        </Link>
                                    </HStack>
                                </HStack>
                            ))}
                        </VStack>
                    </Box>

                    <ModalFooter w={'100%'} px={16}>
                        <Button
                            my={4}
                            type="submit"
                            bg="secondary"
                            py='4'
                            width="full"
                            borderRadius={'50'}
                            fontSize={'sm'}
                        >
                            Next
                        </Button>
                    </ModalFooter>
                </ModalContent>

                <CreateNewGroup isOpen={isCreateNewGroupOpen} onClose={onCreateNewGroupClose} />
                <ViewList isOpen={isViewListOpen} onClose={onViewListClose} group={selectedGroupName} />

                <ConfirmDialog
                    isOpen={isConfirmDialogOpen}
                    onClose={handleClose}
                    onConfirm={handleConfirm}
                    title="Are you sure?"
                    body="This action cannot be undone."
                />
            </Modal>
        </>
    );
}
