import {
    Container, VStack, Text, Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Switch,
    TableContainer,
    Button,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSubscriptionFeatures } from '../../providers/SubscriptionFeatureProvider';

export default function SubscriptionFeatures() {
    const { subscriptionFeatures, editSubscriptionFeature } = useSubscriptionFeatures();

    // Initialize state
    const [activePlans, setActivePlans] = useState({
        chat: subscriptionFeatures.chat || 'FREE',
        voice_call: subscriptionFeatures.voice_call || 'FREE',
        video_call: subscriptionFeatures.video_call || 'FREE',
        accept_gifts: subscriptionFeatures.accept_gifts || 'FREE',
        give_gifts: subscriptionFeatures.give_gifts || 'FREE',
    });

    useEffect(() => {
        // Update state when subscriptionFeatures changes
        setActivePlans({
            chat: subscriptionFeatures.chat || 'FREE',
            voice_call: subscriptionFeatures.voice_call || 'FREE',
            video_call: subscriptionFeatures.video_call || 'FREE',
            accept_gifts: subscriptionFeatures.accept_gifts || 'FREE',
            give_gifts: subscriptionFeatures.give_gifts || 'FREE',
        });
    }, [subscriptionFeatures]);

    const handleSwitchChange = (feature, plan) => {
        // Update the active plan for the feature
        setActivePlans((prevState) => ({
            ...prevState,
            [feature]: plan,
        }));
    };

    const handleUpdate = async () => {
        // Call the API to update the features
        await editSubscriptionFeature(
            activePlans.chat,
            activePlans.voice_call,
            activePlans.video_call,
            activePlans.accept_gifts,
            activePlans.give_gifts
        );
    };

    return (
        <Container maxWidth='9xl' px={6}>
            <VStack alignItems='start' w='100%' spacing='6'>
                <Text className='custom' fontSize='2xl'>Subscription Features</Text>

                <TableContainer border='1px solid black' borderRadius='md'>
                    <Table size='md'>
                        <Thead>
                            <Tr>
                                <Th>S.No</Th>
                                <Th>Feature</Th>
                                <Th>Free Plan</Th>
                                <Th>Basic Plan</Th>
                                <Th>Elite Plan</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td>1</Td>
                                <Td>Chat</Td>
                                <Td>
                                    <Switch
                                        colorScheme='blue'
                                        size='lg'
                                        isChecked={activePlans.chat === 'FREE'}
                                        onChange={() => handleSwitchChange('chat', 'FREE')}
                                    />
                                </Td>
                                <Td>
                                    <Switch
                                        colorScheme='purple'
                                        size='lg'
                                        isChecked={activePlans.chat === 'BASIC'}
                                        onChange={() => handleSwitchChange('chat', 'BASIC')}
                                    />
                                </Td>
                                <Td>
                                    <Switch
                                        colorScheme='green'
                                        size='lg'
                                        isChecked={activePlans.chat === 'PREMIUM'}
                                        onChange={() => handleSwitchChange('chat', 'PREMIUM')}
                                    />
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>2</Td>
                                <Td>Voice Call</Td>
                                <Td>
                                    <Switch
                                        colorScheme='blue'
                                        size='lg'
                                        isChecked={activePlans.voice_call === 'FREE'}
                                        onChange={() => handleSwitchChange('voice_call', 'FREE')}
                                    />
                                </Td>
                                <Td>
                                    <Switch
                                        colorScheme='purple'
                                        size='lg'
                                        isChecked={activePlans.voice_call === 'BASIC'}
                                        onChange={() => handleSwitchChange('voice_call', 'BASIC')}
                                    />
                                </Td>
                                <Td>
                                    <Switch
                                        colorScheme='green'
                                        size='lg'
                                        isChecked={activePlans.voice_call === 'PREMIUM'}
                                        onChange={() => handleSwitchChange('voice_call', 'PREMIUM')}
                                    />
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>3</Td>
                                <Td>Video Call</Td>
                                <Td>
                                    <Switch
                                        colorScheme='blue'
                                        size='lg'
                                        isChecked={activePlans.video_call === 'FREE'}
                                        onChange={() => handleSwitchChange('video_call', 'FREE')}
                                    />
                                </Td>
                                <Td>
                                    <Switch
                                        colorScheme='purple'
                                        size='lg'
                                        isChecked={activePlans.video_call === 'BASIC'}
                                        onChange={() => handleSwitchChange('video_call', 'BASIC')}
                                    />
                                </Td>
                                <Td>
                                    <Switch
                                        colorScheme='green'
                                        size='lg'
                                        isChecked={activePlans.video_call === 'PREMIUM'}
                                        onChange={() => handleSwitchChange('video_call', 'PREMIUM')}
                                    />
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>4</Td>
                                <Td>Accept Gifts</Td>
                                <Td>
                                    <Switch
                                        colorScheme='blue'
                                        size='lg'
                                        isChecked={activePlans.accept_gifts === 'FREE'}
                                        onChange={() => handleSwitchChange('accept_gifts', 'FREE')}
                                    />
                                </Td>
                                <Td>
                                    <Switch
                                        colorScheme='purple'
                                        size='lg'
                                        isChecked={activePlans.accept_gifts === 'BASIC'}
                                        onChange={() => handleSwitchChange('accept_gifts', 'BASIC')}
                                    />
                                </Td>
                                <Td>
                                    <Switch
                                        colorScheme='green'
                                        size='lg'
                                        isChecked={activePlans.accept_gifts === 'PREMIUM'}
                                        onChange={() => handleSwitchChange('accept_gifts', 'PREMIUM')}
                                    />
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>5</Td>
                                <Td>Give Gifts</Td>
                                <Td>
                                    <Switch
                                        colorScheme='blue'
                                        size='lg'
                                        isChecked={activePlans.give_gifts === 'FREE'}
                                        onChange={() => handleSwitchChange('give_gifts', 'FREE')}
                                    />
                                </Td>
                                <Td>
                                    <Switch
                                        colorScheme='purple'
                                        size='lg'
                                        isChecked={activePlans.give_gifts === 'BASIC'}
                                        onChange={() => handleSwitchChange('give_gifts', 'BASIC')}
                                    />
                                </Td>
                                <Td>
                                    <Switch
                                        colorScheme='green'
                                        size='lg'
                                        isChecked={activePlans.give_gifts === 'PREMIUM'}
                                        onChange={() => handleSwitchChange('give_gifts', 'PREMIUM')}
                                    />
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>

                <VStack mb={4} alignItems='center' w={{ base: '100%', md: '50%', lg: '44%' }}>
                    <Button
                        mt={6}
                        type="submit"
                        bg="secondary"
                        py='6'
                        width="full"
                        borderRadius={'50'}
                        fontSize={'sm'}
                        onClick={handleUpdate}
                    >
                        Update
                    </Button>
                </VStack>

            </VStack>

        </Container>
    );
}
