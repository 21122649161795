import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { url } from "../config";
import { toast } from "react-toastify";
import { useAuth } from "./AuthProvider";
import { useNavigate } from 'react-router-dom';

const VirtualGiftContext = createContext();

const VirtualGiftProvider = ({ children }) => {
    const navigate = useNavigate();

    const [virtualGifts, setVirtualGift] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);

    const { token, logged , logout} = useAuth();

    async function addVirtualGift(gift_name, price, description, is_active) {
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var res = await axios.post(`${url}/admin/virtualgift`,
                {
                    gift_name,
                    price,
                    description,
                    is_active
                }, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }
            );
            await getVirtualGift();
            toast.success("Added !");
            navigate('/virtual-gifts/manage-your-gifts');
            return res.data;
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                toast.error(error.response.data.error);
            }
            if (error.response.status === 401) {
                toast.error("Session Expired");
                logout();
            }
            throw error;
        }
    }

    async function editVirtualGift(id, gift_name, price, description, is_active) {
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            const res = await axios.put(`${url}/admin/virtualgift`, {
                id,
                gift_name, 
                price, 
                description,
                is_active
            }, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            });
            await getVirtualGift();
            toast.success("Updated !");
            return res.data;
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                toast.error(error.response.data.error);
            }
            throw error;
        }
    }

    async function getVirtualGift( page ) {
        console.log("Get Faqs");
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var { data } = await axios.get(`${url}/admin/virtualgift?page=${page}`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
            });
            setVirtualGift(data.data);
            setCurrentPage(data.page);
            setTotalPages(data.pages);

        } catch (error) {
            console.error("GET FAQ ERROR");
            console.error(error);
            if (error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
                // logout();
            }
            return "err";
        }
    }

    async function deleteVirtualGift(id) {
        console.log(id)
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            await axios.delete(`${url}/admin/virtualgift`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                data: {
                    "id": id,
                },
            }
            );
            await getVirtualGift();
            toast.success("DELETED Successfully !");
        } catch (error) {
            console.error(error);
            toast.error("Can't delete active Location With Members.");
            return "err";
        }
    }

    useEffect(() => {
        getVirtualGift(1);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            loading,
            virtualGifts,
            currentPage,
            totalPages,
            addVirtualGift,
            editVirtualGift,
            deleteVirtualGift,
            getVirtualGift
        }),
        [logged, loading, virtualGifts, currentPage, totalPages, getVirtualGift] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Provide the authentication context to the children components
    return (
        <VirtualGiftContext.Provider value={contextValue}>{children}</VirtualGiftContext.Provider>
    );
};

export const useVirtualGift = () => {
    return useContext(VirtualGiftContext);
};

export default VirtualGiftProvider;

