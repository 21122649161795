import {
    Card,
    Container,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
    useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { RiUserAddLine } from "react-icons/ri";
import ReferralsViewList from '../../models/ReferralsViewList';
import { Link } from 'react-router-dom';

export default function Referrals() {
    const { isOpen: isViewListOpen, onOpen: onViewListOpen, onClose: onViewListClose } = useDisclosure();

    const camelCaseStyle = {
        textTransform: 'capitalize',
        whiteSpace: 'nowrap', // Prevents breaking words
    };

    return (
        <Container maxWidth='9xl'>

            <Card mt='5' bg='primary.200' pl={4} py={6} w={{ base: '85%', md: '25%', lg: '15%' }} mx={{ base: 6, md: 0 }}>
                <VStack spacing={0} alignItems={'start'}>
                    <RiUserAddLine size={25} color='#F51E9A' />
                    <Text className='custom' fontSize='2xl' color='primary.800'>12345</Text>
                    <Text fontSize={'xs'}>Total Referrals</Text>
                </VStack>
            </Card>

            <VStack alignItems={'start'} mt={8} mb={4} mx={{ base: 6, md: 0 }} spacing={4}>

                <TableContainer w={'100%'} borderRadius="md" style={{ boxShadow: '0 0 1px rgba(0, 0, 0, 0.2)' }}>
                    <Table variant="simple" >
                        <Thead>
                            <Tr bg="primary.100">
                                <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">Referral Code</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">Name</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">No of Referrals</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'} textAlign="center">Uses</Th>
                            </Tr>
                        </Thead>
                        <Tbody
                            sx={{ 'tr:not(:last-of-type)': { borderBottom: '2px solid', borderColor: 'gray.200' } }}
                        >
                            {[...Array(5)].map((_, index) => (
                                <Tr key={index} fontSize="sm">
                                    <Td textAlign="center">#123</Td>
                                    <Td textAlign="center">John Doe</Td>
                                    <Td textAlign="center">12</Td>
                                    <Td textAlign="center" color={'#F51E9A'}>
                                        <Link onClick={() => onViewListOpen()}>
                                            View List
                                        </Link>
                                    </Td>
                                </Tr>
                            ))}

                        </Tbody>
                    </Table>
                </TableContainer>
            </VStack>
            <ReferralsViewList isOpen={isViewListOpen} onClose={onViewListClose} />
        </Container>
    )
}

