import { useFormik } from "formik";
import {
    Box,
    Button,
    FormControl,
    Wrap,
    Input,
    VStack,
    Text,
    HStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import { toast } from "react-toastify";

export default function OtpPage() {
    const navigate = useNavigate();
    const { login, loginOtp } = useAuth();
    const { state } = useLocation();

    const formik = useFormik({
        initialValues: {
            otp: "",
        },
        onSubmit: async (values) => {
            //
            // console.log(state);
            // console.log(values);
            try {
                await login(state.email, values.otp);
                navigate('/dashboard')
            } catch (error) {
                console.error(error);
                toast.error("Invalid OTP");
            }
        }
    });

    async function resendOtp() {
        try {
            await loginOtp(state.email, state.password);
        } catch (error) {
            toast.error("Error Sending OTP");
        }
    }

    return (
        <HStack w='100%' h='100vh' spacing='0'>
            <Box style={{
                backgroundImage: "url('./login.jpg')",
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center'
            }} display={{ base: 'none', lg: 'flex' }} w='50%' h='100%'>
            </Box>
            <Box
                w={{ base: '100%', lg: '50%' }}
                h='100%'
                display='flex'
                alignItems='center'
                justifyContent='center'
                p={{ base: 4, md: 0 }}
            >
                <form onSubmit={formik.handleSubmit}>
                    <VStack
                        spacing='6'
                        bg="white"
                        p={6}
                        w={'100%'}
                        rounded="md"
                        boxShadow="0 4px 6px rgba(0, 0, 0, 0.1), 0 -2px 4px rgba(0, 0, 0, 0.06)">
                        <VStack mb={4}>
                            <Wrap>
                                <Text fontSize='2xl' as='h3' fontWeight='500' color='primary.800'>Verify</Text>
                                <Text fontSize='2xl' as='h3' fontWeight='500'>your email address</Text>
                            </Wrap>
                            <Wrap>
                                <Text fontSize='sm' fontWeight='400' color='grey.200'>Code is sent to</Text>
                                <Text fontSize='sm' fontWeight='400' color='primary.800'>{state.email}</Text>
                            </Wrap>
                        </VStack>
                        <FormControl>
                            <Input
                                id="otp"
                                name="otp"
                                type="number"
                                variant="filled"
                                required={true}
                                placeholder="Enter OTP"
                                fontSize={'sm'}
                                p={6}
                                bg={'none'}
                                borderColor={"gray.100"}
                                _focus={{
                                    borderColor: "primary.800",
                                }}
                                borderRadius={'50'}
                                value={formik.values.otp}
                                onChange={formik.handleChange}
                            />
                        </FormControl>
                        <VStack spacing={0}>
                            <Text fontSize='sm' fontWeight='400' color='grey.200'> Didn't receive any code?</Text>
                            <Button variant={'ghost'} onClick={resendOtp} fontSize='sm' fontWeight='500' color='primary.800'>Request new code again.</Button>
                        </VStack>

                        <Button
                            mt={4}
                            disabled={formik.isValid}
                            type="submit"
                            bg="secondary"
                            py='6'
                            width="full"
                            borderRadius={'50'}
                            fontSize={'sm'}
                        >
                            Verify & Login
                        </Button>
                    </VStack>
                </form>
            </Box>
        </HStack>
    );
}