import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { url } from "../config";
import { toast } from "react-toastify";
import { useAuth } from "./AuthProvider";
import axios from "axios";

const UserContext = createContext();

const UserProvider = ({ children }) => {
    const [users, setUsers] = useState([]);
    const [totalUser, setTotalUser] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalUserThisMonth, setTotalUserThisMonth] = useState(0);
    const [totalActiveUser, setTotalActiveUser] = useState(0);
    const [loading, setLoading] = useState(true);

    const { token, logged, logout } = useAuth();

    async function getUsers(query = "", page = 1) {
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            let data;
            if (query) {
                const endpoint = `${url}/admin/user/search`;
                const response = await axios.post(endpoint, { query }, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    }
                });
                data = response.data;
            } else {
                const endpoint = `${url}/admin/users?page=${page}`;
                const response = await axios.get(endpoint, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    }
                });
                data = response.data;
                setCurrentPage(data.page);
                setTotalPages(data.pages);
            }
            setUsers(data.data);
            setCurrentPage(data.page);
            setTotalPages(data.pages);
        } catch (error) {
            console.error("GET USERS ERROR");
            if (error.response && error.response.status === 401) {
                logout();
            }
        } finally {
            setLoading(false);
        }
    }

    async function searchUser(query) {
        await getUsers(query, currentPage);  // Pass currentPage when searching
    }

    async function totalUsers() {
        console.log("Get Total User");
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var { data } = await axios.get(`${url}/admin/total-users`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
            });
            setTotalUser(data.data);
            await getUsers();
        } catch (error) {
            console.error("GET TOTAL COUNT USERS ERROR");
            console.error(error);
            if (error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
                logout();
            }
            return "err";
        }
    }

    async function totalUsersThisMonth() {
        console.log("Get Total User In This Month");
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var { data } = await axios.get(`${url}/admin/active-users/current-month`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
            });
            setTotalUserThisMonth(data.data);
            await getUsers();

        } catch (error) {
            console.error("GET TOTAL USERS IN THIS ERROR");
            console.error(error);
            if (error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
                logout();
            }
            return "err";
        }
    }

    async function totalActiveUsers() {
        console.log("Get Total Active User");
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var { data } = await axios.get(`${url}/admin/active-users/last-30-days`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
            });
            setTotalActiveUser(data.data);
            await getUsers();

        } catch (error) {
            console.error("GET TOTAL ACTIVE USERS ERROR");
            console.error(error);
            if (error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
                logout();
            }
            return "err";
        }
    }

    async function banUser(userId) {
        console.log("Ban User");
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var { data } = await axios.post(`${url}/admin/ban-user`,
                { userId },
                {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                });
            await getUsers();
            await totalUsersThisMonth();
            await totalActiveUsers();
            toast.success("User has been banned successfully");

        } catch (error) {
            console.error("BAN USERS ERROR");
            console.error(error);
            if (error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
                logout();
            }
            return "err";
        }
    }

    async function activeUser(userId) {
        console.log("Active User");
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var { data } = await axios.post(`${url}/admin/active-user`,
                { userId },
                {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                });
            await getUsers();
            toast.success("User has been active successfully");

        } catch (error) {
            console.error("ACTIVE USERS ERROR");
            console.error(error);
            if (error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
                logout();
            }
            return "err";
        }
    }

    async function deleteUser(userId) {
        console.log("DELETE USER")
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            await axios.delete(`${url}/admin/user/delete`, {
                data: { userId },
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            });
            await getUsers();
            await totalUsers();
            await totalUsersThisMonth();
            await totalActiveUsers();
            toast.success("DELETED Successfully!");
        } catch (error) {
            console.error(error);
            return "err";
        }
    }

    useEffect(() => {
        getUsers();
        totalUsers();
        totalUsersThisMonth();
        totalActiveUsers();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            loading,
            users,
            totalUser,
            totalUserThisMonth,
            totalActiveUser,
            getUsers,
            searchUser,
            totalUsers,
            totalUsersThisMonth,
            totalActiveUsers,
            banUser,
            activeUser,
            deleteUser,
            currentPage,
            totalPages
        }),
        [logged, loading, users, totalUser, totalUserThisMonth, totalActiveUser, currentPage, totalPages, getUsers] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Provide the authentication context to the children components
    return (
        <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
    );
};

export const useUsers = () => {
    return useContext(UserContext);
};

export default UserProvider;
