import {
    Button,
    Card,
    Container,
    HStack,
    Icon,
    Stack,
    Switch,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
    useDisclosure
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { FiPlusCircle } from "react-icons/fi";
import { LuBellDot } from "react-icons/lu";
import { FaCheckCircle } from "react-icons/fa";
import { RxCrossCircled } from "react-icons/rx";
import PushNotification from '../../../models/push_notifications/PushNotification';
import CreateNewNotification from '../../../models/CreateNewNotification';

export default function NotificationList() {
    const { isOpen: isNotificationOpen, onOpen: onNotificationOpen, onClose: onNotificationClose } = useDisclosure();
    const { isOpen: isCreateNotificationOpen, onOpen: onCreateNotificationOpen, onClose: onCreateNotificationClose } = useDisclosure();

    const handleSwitchClick = () => {
        onNotificationOpen();
    };

    return (
        <>
            <Container maxWidth='9xl' px={6}>
                <Stack
                    direction={{ base: 'column', md: 'row' }}
                    mt='5'
                    w={'100%'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Text className='custom' fontSize="lg" fontWeight="500" color="primary.800">
                        <Link to='/settings'>
                            Settings &gt;{' '}
                        </Link>
                        <Text as="span" color="black" pl={2}>
                            Push Notifications
                        </Text>
                    </Text>

                    <Link onClick={() => onCreateNotificationOpen()}>
                        <HStack
                            p={{ base: '4', md: 6 }}
                            bg={'secondary'}
                            borderRadius={{ base: '22px', md: '22px', lg: '42px' }}
                            width={{ base: '100%', md: 'auto' }}
                            mt={{ base: 4, md: 0 }}
                            justifyContent={'center'}
                        >

                            <FiPlusCircle size={'22px'} />

                            <Text fontSize={{ md: 'sm', lg: 'md' }} fontWeight={'600'}>
                                Create New Notification
                            </Text>
                        </HStack>
                    </Link>

                </Stack>


                <VStack w='100%' alignItems={'start'} mt={8}>
                    <Text className='custom' fontSize="lg" fontWeight="500">
                        Send Notifications
                    </Text>

                    <PushNotification />
                </VStack>

                <VStack w={'100%'} alignItems={'start'} mt={8} spacing={4}>
                    <Text className='custom' fontSize="lg" fontWeight="500">
                        Scheduled Notification List
                    </Text>

                    <Stack direction={{ base: 'column', lg: 'row' }} w={'100%'} alignItems={'start'}>

                        <TableContainer w={{ base: '100%', lg: "100%" }} borderRadius="md" style={{ boxShadow: '0 0 1px rgba(0, 0, 0, 0.2)' }}>
                            <Table variant="simple" >
                                <Thead>
                                    <Tr bg="primary.100">
                                        {/* <Th>SR NO</Th> */}
                                        <Th>Date & Time</Th>
                                        <Th>Image</Th>
                                        <Th>Title</Th>
                                        <Th>Action</Th>
                                    </Tr>
                                </Thead>
                                <Tbody
                                    sx={{ 'tr:not(:last-of-type)': { borderBottom: '2px solid', borderColor: 'gray.200' } }}
                                >
                                    {[...Array(5)].map((_, index) => (
                                        <Tr key={index} fontSize="sm">
                                            {/* <Td>#123</Td> */}
                                            <Td>02/12/2023</Td>
                                            <Td>Image</Td>
                                            <Td>Lorem ipsum is typically a corrupted</Td>
                                            <Td>
                                                {/* <Button
                                                    colorScheme='green'
                                                    leftIcon={<Icon as={FaCheckCircle} boxSize={4} />}
                                                    borderRadius='50px'
                                                    fontWeight={500}
                                                    fontSize={'xs'}
                                                    mr={2}
                                                >
                                                    Accept
                                                </Button> */}

                                                <Button
                                                    colorScheme='red'
                                                    leftIcon={<Icon as={RxCrossCircled} boxSize={4} />}
                                                    borderRadius='50px'
                                                    fontWeight={500}
                                                    fontSize={'xs'}
                                                >
                                                    Delete
                                                </Button>
                                            </Td>
                                        </Tr>
                                    ))}

                                </Tbody>
                            </Table>
                        </TableContainer>

                        {/* <Card
                            w={{ base: '100%', lg: "30%" }}
                            px={2}
                            py={4}
                            style={{ boxShadow: '0 0 3px rgba(0, 0, 0, 0.3)' }}
                            mt={{ base: 4, lg: 0 }}
                            mb={{ base: 4, md: 0 }}
                        >
                            <Container
                                borderWidth="1px"
                                borderColor="#e8e6e6"
                                borderRadius="lg"
                                px={2}
                                py={4}
                                mb={4}
                            >
                                <HStack justifyContent={'space-between'}>
                                    <HStack>
                                        <LuBellDot color='#FFCC18' size={25} />

                                        <VStack spacing={0} alignItems={'start'}>
                                            <Text className='custom' fontSize="sm" fontWeight="500" color='primary.800'>
                                                Push Notifications
                                            </Text>
                                            <Text color={'#919090'} fontSize="xs" fontWeight={'500'}>
                                                Send new notification to users
                                            </Text>
                                        </VStack>
                                    </HStack>

                                    <Link onClick={handleSwitchClick}>
                                        <Switch size='lg' colorScheme='pink' />
                                    </Link>

                                </HStack>
                            </Container>

                            <VStack maxH="40vh" overflowY="auto">
                                {[...Array(10)].map((_, index) => (
                                    <Container
                                        key={index}
                                        borderWidth="1px"
                                        borderColor="#e8e6e6"
                                        borderRadius="lg"
                                        p={2}
                                    >
                                        <Text className='custom' fontSize="sm" fontWeight="500" color='primary.900'>
                                            Notification Number1
                                        </Text>
                                        <Text fontSize="xs" fontWeight={'500'}>Lorem ipsum is typically a corrupted version of De finibus bonorum et malorum.</Text>
                                    </Container>
                                ))}
                            </VStack>
                        </Card> */}
                    </Stack>
                </VStack>

                {/* <PushNotification isOpen={isNotificationOpen} onClose={onNotificationClose} onOpen={onNotificationOpen} /> */}
                <CreateNewNotification isOpen={isCreateNotificationOpen} onClose={onCreateNotificationClose} />

            </Container>
        </>
    )
}


