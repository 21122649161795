import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    Button,
    VStack,
    ModalHeader,
    ModalCloseButton,
    Input,
    FormControl,
} from '@chakra-ui/react';
import { useFormik } from "formik";
import { useFaqs } from '../../providers/FaqProvider';
import { toast } from 'react-toastify';

export default function CreateFaq({ isOpen, onClose }) {
    const { addFaq } = useFaqs();

    const formik = useFormik({
        initialValues: {
            title: "",
            content: "",
        },
        onSubmit: async (values) => {
            if (!values.title.trim()) {
                return toast.error("FAQ Question cannot be empty or only spaces");
            }
            if (!values.content.trim()) {
                return toast.error("FAQ Answer cannot be empty or only spaces");
            }

            await addFaq(values.title, values.content);
            values.title = "";
            values.content = "";
            onClose();
        }
    });

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered onOpen size={{ base: 'sm', md: '2xl' }}>
                <ModalOverlay />
                <ModalContent mx={{ base: 4, md: 0 }}>
                    <ModalHeader fontSize={'md'} color={'white'} bg='primary.400' w='100%'>
                        Create New FAQ
                    </ModalHeader>
                    <ModalCloseButton color={'white'} />

                    <form onSubmit={formik.handleSubmit}>
                        <VStack w={'auto'} alignItems={'start'} mx={4} mt={6}>

                            <FormControl>
                                <Input
                                    id="title"
                                    name="title"
                                    type="text"
                                    variant="filled"
                                    required={true}
                                    fontSize="sm"
                                    py={4}
                                    bg="none"
                                    borderColor="gray.100"
                                    placeholder="FAQ Question"
                                    _focus={{
                                        borderColor: "primary.800",
                                    }}
                                    borderRadius="10"
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                />
                            </FormControl>

                            <FormControl>
                                <Input
                                    id="content"
                                    name="content"
                                    type="text"
                                    variant="filled"
                                    required={true}
                                    fontSize="sm"
                                    py={4}
                                    bg="none"
                                    borderColor="gray.100"
                                    placeholder="FAQ Answer"
                                    _focus={{
                                        borderColor: "primary.800",
                                    }}
                                    borderRadius="10"
                                    value={formik.values.content}
                                    onChange={formik.handleChange}
                                />
                            </FormControl>

                            <ModalFooter w={'100%'} px={{ lg: 44 }}>
                                <Button
                                    my={4}
                                    type="submit"
                                    bg="secondary"
                                    py='6'
                                    width="full"
                                    borderRadius={'50'}
                                    fontSize={'sm'}
                                >
                                    Add New FAQ
                                </Button>
                            </ModalFooter>

                        </VStack>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}



