import {
    Box,
    HStack,
    Flex,
    Text,
    useBreakpointValue,
    Menu,
    MenuButton,
    MenuList,
    VStack,
    MenuItem,
    Image,
} from '@chakra-ui/react'
import React from 'react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SideBar from './SideBar';
import { IoNotificationsOutline } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom';
import { MdKeyboardArrowDown } from "react-icons/md";
import { useAuth } from '../../providers/AuthProvider';

export default function Navbar() {
    let navigate = useNavigate();

    const sideBarVariant = useBreakpointValue({ base: 'drawer', md: 'sidebar' });

    const { user, logout } = useAuth();

    const handleLogout = () => {
        console.log('logout');
        logout();
        navigate('/')
    };

    return (
        <>
            <Box bg='white' py={2} px={4} w='100%' boxShadow={'md'}>
                <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>

                    {sideBarVariant === 'drawer' && (
                        // <IconButton
                        //     size={'md'}
                        //     icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        //     aria-label={'Open Menu'}
                        //     display={{ xl: 'none' }}
                        //     onClick={isOpen ? onClose : onOpen}
                        // />
                        <SideBar />
                    )}

                    <Flex w='100%'>
                        <HStack justifyContent='space-between' w='100%'>
                            <Text pl={{ base: 2, md: 0 }} className='custom' fontSize={{ base: 'xs', md: 'xl' }}>
                                Welcome <Text as="span" color='primary.800' pl={2}>{user.first_name}</Text> 🖐
                            </Text>

                            <HStack spacing={6}>
                                {/* <Menu> */}
                                {/* <MenuButton>
                                        <Card bg='secondary' p={1}>
                                            <IoNotificationsOutline size='28' color='white' />

                                            <Container>
                                                <Box
                                                    bg='#E22937'
                                                    w='6px'
                                                    h='6px'
                                                    display='flex'
                                                    justifyContent='center'
                                                    alignItems='center'
                                                    rounded='50%'
                                                    position={'absolute'}
                                                    top='1'
                                                >

                                                </Box>
                                            </Container>
                                        </Card>

                                    </MenuButton> */}
                                {/* <MenuList rounded='xl' bg='white' p='3'>
                                        <Text as='h5' fontSize='md' py='2'>Notifications</Text>
                                        <MenuItem maxWidth='240px'>
                                            <VStack alignItems='start'>
                                                <HStack >
                                                    <Box bg='#9F9F9F' w='30px' h='30px' display='flex' justifyContent='center' alignItems='center' rounded='50%'>
                                                        <IoNotificationsOutline size='18' />
                                                    </Box>

                                                    <VStack spacing='0.3' alignItems={'start'}>
                                                        <Text >Jane Doe</Text>
                                                        <Text fontSize={'9px'}>2 hrs ago</Text>
                                                    </VStack>
                                                </HStack>
                                                <Text opacity={'0.8'} fontSize={'10px'}>lorem ispas dasjd asdasdkajs dk lorem ispas dasjd lorem ispas dasjd lorem ispas dasjd </Text>
                                            </VStack>
                                        </MenuItem>
                                        <MenuItem maxWidth='240px'>
                                            <VStack alignItems='start'>
                                                <HStack >
                                                    <Box bg='#9F9F9F' w='30px' h='30px' display='flex' justifyContent='center' alignItems='center' rounded='50%'>
                                                        <IoNotificationsOutline size='18' />
                                                    </Box>
                                                    <VStack spacing='0.5' alignItems={'start'}>
                                                        <Text >Jane Doe</Text>
                                                        <Text fontSize={'9px'}>2 hrs ago</Text>
                                                    </VStack>
                                                </HStack>
                                                <Text opacity={'0.8'} fontSize={'10px'}>lorem ispas dasjd asdasdkajs dk lorem ispas dasjd lorem ispas dasjd lorem ispas dasjd </Text>
                                            </VStack>
                                        </MenuItem>
                                    </MenuList> */}
                                {/* </Menu> */}

                                <Menu>
                                    <MenuButton>
                                        <HStack spacing='2'>
                                            {user.profile_picture ?
                                                <Image src={user.profile_picture} w="12" h="12" objectFit="cover" borderRadius="50%" />
                                                :
                                                <img src='./favicon.ico' width={40} alt='logo' />
                                            }
                                            <VStack spacing={0} alignItems={'start'}>
                                                <Text fontSize={'sm'} fontWeight={600}>{user.first_name}</Text>
                                                <Text fontSize={'sm'}>Admin</Text>
                                            </VStack>
                                            <MdKeyboardArrowDown size='20' opacity={'0.8'} />
                                        </HStack>
                                    </MenuButton>
                                    <MenuList>
                                        <Link to={'/settings'}>
                                            <MenuItem minH='40px'>
                                                <Text pl={1}>Settings</Text>
                                            </MenuItem>
                                        </Link>

                                        <MenuItem minH='40px'>
                                            <Link to={'/admin-management'}>
                                                <Text pl={1}>Admin Management</Text>
                                            </Link>
                                        </MenuItem>

                                        <MenuItem minH='40px' onClick={handleLogout}>
                                            <Text pl={1}>Log Out</Text>
                                        </MenuItem>

                                    </MenuList>
                                </Menu>
                            </HStack>
                        </HStack>
                    </Flex>
                </Flex>
            </Box >
            <ToastContainer />
        </>
    )
}
