import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { url } from "../config";
import { useAuth } from "./AuthProvider";

const RatingsContext = createContext();

const RatingsProvider = ({ children }) => {
    const [rating, setRating] = useState([]);
    const [totalRating, setTotalRating] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);

    const { token, logged, logout } = useAuth();

    async function getRating(page) {
        console.log("Get Rating", page);
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            const { data } = await axios.get(`${url}/admin/ratings?page=${page}`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            });
            setRating(data.data);
            setTotalRating(data.total);
            setCurrentPage(data.page);
            setTotalPages(data.pages);
        } catch (error) {
            console.error("GET RATINGS ERROR", error);
            if (error.response?.status === 401) {
                console.error("NOT AUTHENTICATED");
                logout();
            }
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getRating(1);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const contextValue = useMemo(() => ({
        loading,
        rating,
        totalRating,
        currentPage,
        totalPages,
        getRating,
    }), [loading, rating, totalRating, currentPage, totalPages]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <RatingsContext.Provider value={contextValue}>
            {children}
        </RatingsContext.Provider>
    );
};

export const useRatings = () => useContext(RatingsContext);

export default RatingsProvider;
