import { Box, HStack, Text, VStack, Image } from '@chakra-ui/react'
import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import { MdDashboard } from "react-icons/md";
import { primarycolor } from '../../config';
import { FaUserGear } from "react-icons/fa6";
import { MdOutlineSubscriptions } from "react-icons/md";
import { LiaGiftsSolid } from "react-icons/lia";
import { CgNotes } from "react-icons/cg";
import { PiUsersDuotone } from "react-icons/pi";
import { FaRegStar } from "react-icons/fa";
import { IoTicketOutline } from "react-icons/io5";
import { IoIosSwitch } from "react-icons/io";


export default function SidebarLinks({ onClick }) {
    const location = useLocation();

    return (
        <>
            <VStack
                onClick={onClick}
                alignItems='start'
                w='100%'
                overflowY='auto'
                h='100vh'
                maxW='300px'
                bg={primarycolor}
                // transform="perspective(2000px) rotateY(5deg)"
                // transformOrigin="left"
                // clipPath="polygon(0 0, 100% 2%, 100% 98%, 0 100%)"
                // borderTopRightRadius="lg"
                // borderBottomRightRadius="lg"
                borderTopRightRadius="40 ml={4}px"
                borderBottomRightRadius="40 ml={4}px"
            >
                <Box pt={6} w='100%'>
                    <Link to={'/'}>
                        <HStack alignItems='center' justifyContent='center' w='100%' mb='4'>
                            <Image src='/logo192.png' w='80px' />
                        </HStack>
                    </Link>
                    <Link to={'/dashboard'}>
                        <HStack _hover={{ bg: 'white', color: 'primary.900' }} 
                        p='4' 
                        bg={location.pathname.includes("dashboard") ? 'white' : ''}
                        color={location.pathname.includes("dashboard") ? 'primary.900' : 'white'}
                        >
                            <MdDashboard size='20px' />
                            <Text fontWeight='500'>Dashboard</Text>
                        </HStack>
                    </Link>
                    <Link to={'/user-management'}>
                        <HStack
                            _hover={{ bg: 'white', color: 'primary.900' }}
                            bg={location.pathname.includes("user-management") ? 'white' : ''}
                            color={location.pathname.includes("user-management") ? 'primary.900' : 'white'}
                            p='4'
                        >
                            <FaUserGear size='20px' />
                            <Text fontWeight='500'>User Management</Text>
                        </HStack>
                    </Link>
                    <Link to={'/subscription-management'}>
                        <HStack
                            _hover={{ bg: 'white', color: 'primary.900' }}
                            bg={location.pathname.includes("subscription-management") ? 'white' : ''}
                            color={location.pathname.includes("subscription-management") ? 'primary.900' : 'white'}
                            p='4'
                        >
                            <MdOutlineSubscriptions size='20px' />
                            <Text fontWeight='500'>Subscription Plan</Text>
                        </HStack>
                    </Link>
                    <Link to={'/subscription-features'}>
                        <HStack
                            _hover={{ bg: 'white', color: 'primary.900' }}
                            bg={location.pathname.includes("subscription-features") ? 'white' : ''}
                            color={location.pathname.includes("subscription-features") ? 'primary.900' : 'white'}
                            p='4'
                        >
                            <IoIosSwitch size='20px' />
                            <Text fontWeight='500'>Subscription Features</Text>
                        </HStack>
                    </Link>
                    <Link to={'/virtual-gifts'}>
                        <HStack
                            _hover={{ bg: 'white', color: 'primary.900' }}
                            bg={location.pathname.includes("virtual-gifts") ? 'white' : ''}
                            color={location.pathname.includes("virtual-gifts") ? 'primary.900' : 'white'}
                            p='4'
                        >
                            <LiaGiftsSolid size='20px' />
                            <Text fontWeight='500'>Virtual Gifts</Text>
                        </HStack>
                    </Link>
                    <Link to={'/content-management'}>
                        <HStack
                            _hover={{ bg: 'white', color: 'primary.900' }}
                            bg={location.pathname.includes("content-management") ? 'white' : ''}
                            color={location.pathname.includes("content-management") ? 'primary.900' : 'white'}
                            p='4'
                        >
                            <CgNotes size='20px' />
                            <Text fontWeight='500'>Content Management</Text>
                        </HStack>
                    </Link>
                    <Link to={'/referrals'}>
                        <HStack
                            _hover={{ bg: 'white', color: 'primary.900' }}
                            bg={location.pathname.includes("referrals") ? 'white' : ''}
                            color={location.pathname.includes("referrals") ? 'primary.900' : 'white'}
                            p='4'
                        >
                            <PiUsersDuotone size='20px' />
                            <Text fontWeight='500'>Referrals</Text>
                        </HStack>
                    </Link>
                    <Link to={'/ratings'}>
                        <HStack
                            _hover={{ bg: 'white', color: 'primary.900' }}
                            bg={location.pathname.includes("ratings") ? 'white' : ''}
                            color={location.pathname.includes("ratings") ? 'primary.900' : 'white'}
                            p='4'
                        >
                            <FaRegStar size='20px' />
                            <Text fontWeight='500'>Ratings</Text>
                        </HStack>
                    </Link>
                    <Link to={'/ticket-management'}>
                        <HStack
                            _hover={{ bg: 'white', color: 'primary.900' }}
                            bg={location.pathname.includes("ticket-management") ? 'white' : ''}
                            color={location.pathname.includes("ticket-management") ? 'primary.900' : 'white'}
                            p='4'
                        >
                            <IoTicketOutline size='20px' />
                            <Text fontWeight='500'>Ticket Management</Text>
                        </HStack>
                    </Link>
                </Box>
                <Image position='absolute' bottom='10px' left='25px' src='/drawer_bottom.png' h={{ base: '130px', md: '110px' }} />
            </VStack >
        </>
    )
}
