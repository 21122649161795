import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Login from './components/auth/Login';
import PrivateRoutes from './components/utils/PrivateRoutes';
import Dashboard from './components/data/Dashboard';
import OtpPage from './components/auth/OtpPage';
import AuthProvider from './providers/AuthProvider';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import AdminManagement from './components/data/admin_management/AdminManagement';
import ViewUserAccount from './components/data/admin_management/ViewUserAccount';
import Settings from './components/data/settings/Settings';
import YourProfile from './components/data/settings/YourProfile';
import NotificationList from './components/data/settings/push_notifications/NotificationList';
import UserManagement from './components/data/user_management/UserManagement';
import CreateNewUser from './components/data/user_management/CreateNewUser';
import UserDetails from './components/data/user_management/UserDetails';
import PreferenceDetails from './components/data/user_management/PreferenceDetails';
import MatchesDetails from './components/data/user_management/MatchesDetails';
import SubscriptionPlan from './components/data/subscription_plan/SubscriptionPlan';
import ManageYourPlan from './components/data/subscription_plan/ManageYourPlan';
import EditSubscription from './components/data/subscription_plan/EditSubscription';
import ViewSubscription from './components/data/subscription_plan/ViewSubscription';
import ManageSubscriptionFeature from './components/data/subscription_plan/ManageSubscriptionFeature';
import VirtualGifts from './components/data/virtual_gifts/VirtualGifts';
import ManageYourGifts from './components/data/virtual_gifts/ManageYourGifts';
import EditVirtualGift from './components/data/virtual_gifts/EditVirtualGift';
import ViewVirtualGift from './components/data/virtual_gifts/ViewVirtualGift';
import AddNewVirtualGift from './components/data/virtual_gifts/AddNewVirtualGift';
import ContentManagement from './components/data/content_management/ContentManagement';
import Faq from './components/data/content_management/Faq';
import AboutSection from './components/data/content_management/AboutSection';
import PrivacyPolicy from './components/data/content_management/PrivacyPolicy';
import TermsAndCondition from './components/data/content_management/TermsAndCondition';
import Referrals from './components/data/referrals/Referrals';
import Ratings from './components/data/ratings/Ratings';
import TicketManagement from './components/data/ticket_management/TicketManagement';
import SubscriptionFeatures from './components/data/SubscriptionFeatures';
import Unresolved from './components/data/ticket_management/Unresolved';
import Resolved from './components/data/ticket_management/Resolved';

function App() {
  return (
    <AuthProvider key='auth'>
      <Router>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path='/' element={<Dashboard />} />
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/admin-management' element={<AdminManagement />} />
            <Route path='/admin-management/view-user' element={<ViewUserAccount />} />

            <Route path='/settings' element={<Settings />} />
            <Route path='/settings/your-profile' element={<YourProfile />} />
            <Route path='/settings/notification-list' element={<NotificationList />} />

            <Route path='/user-management' element={<UserManagement />} />
            <Route path='/user-management/create-new-user' element={<CreateNewUser />} />
            <Route path='/user-management/user-details' element={<UserDetails />} />
            <Route path='/user-management/preference-details' element={<PreferenceDetails />} />
            <Route path='/user-management/matches-details' element={<MatchesDetails />} />

            <Route path='/subscription-features' element={<SubscriptionFeatures />} />

            <Route path='/subscription-management' element={<SubscriptionPlan />} />
            <Route path='/subscription-management/manage-your-plan' element={<ManageYourPlan />} />
            <Route path='/subscription-management/manage-your-plan/edit-subscription' element={<EditSubscription />} />
            <Route path='/subscription-management/manage-your-plan/view-subscription' element={<ViewSubscription />} />
            <Route path='/subscription-management/manage-subscription-feature' element={<ManageSubscriptionFeature />} />

            <Route path='/virtual-gifts' element={<VirtualGifts />} />
            <Route path='/virtual-gifts/manage-your-gifts' element={<ManageYourGifts />} />
            <Route path='/virtual-gifts/manage-your-gifts/edit-virtual-gift' element={<EditVirtualGift />} />
            <Route path='/virtual-gifts/manage-your-gifts/view-virtual-gift' element={<ViewVirtualGift />} />
            <Route path='/virtual-gifts/add-new-virtual-gift' element={<AddNewVirtualGift />} />

            <Route path='/content-management' element={<ContentManagement />} />
            <Route path='/content-management/faq' element={<Faq />} />
            <Route path='/content-management/about-section' element={<AboutSection />} />
            <Route path='/content-management/terms-and-condition' element={<TermsAndCondition />} />
            <Route path='/content-management/privacy-policy' element={<PrivacyPolicy />} />

            <Route path='/referrals' element={<Referrals />} />

            <Route path='/ratings' element={<Ratings />} />

            <Route path='/ticket-management' element={<TicketManagement />} />
            <Route path='/ticket-management/unresolved' element={<Unresolved />} />
            <Route path='/ticket-management/resolved' element={<Resolved />} />
          </Route>
          <Route path='/login' element={<Login />} />
          <Route path='/otp-page' element={<OtpPage />} />
        </Routes>
      </Router>
      <ToastContainer />
    </AuthProvider>
  );
}

export default App;
