import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { url } from "../config";
import { toast } from "react-toastify";
import { useAuth } from "./AuthProvider"; 

const NotificationGroupContext = createContext();

const NotificationGroupProvider = ({ children }) => { 
    const [groups, setGroup] = useState([]);
    const [usersFromGroup, setUsersFromGroup] = useState([]);
    const [activeUsers, setActiveUser] = useState([]);
    const [loading, setLoading] = useState(true);

    const { token, logged, logout } = useAuth();

    async function addGroup(name, users) {
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var res = await axios.post(`${url}/admin/group`,
                {
                    "name": name,
                    "users": users
                }, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }
            );
            await getGroups();
            toast.success("Added !");
            return res.data;
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                toast.error(error.response.data.message);
            }
            if (error.response.status === 401) {
                toast.error("Session Expired");
                logout();
            }
            throw error;
        }
     }

    async function getGroups(query = "") {
        setLoading(true);
        try {
            let data;
            if (query) {
                const endpoint = `${url}/admin/search-group`;
                const response = await axios.post(endpoint, { query }, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    }
                });
                data = response.data;
            } else {
                const endpoint = `${url}/admin/group`;
                const response = await axios.get(endpoint, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    }
                });
                data = response.data;
            }

            setGroup(data.data);
        } catch (error) {
            console.error("GET USERS ERROR", error);
            toast.error(error.response.data.message);
        } finally {
            setLoading(false);
        }
    }

    async function searchGropName(query) {
        await getGroups(query);
    }

    async function getUsersFromGroup(groupId, query = "") {
        setLoading(true);
        try {
            let data;
            if (query) {
                const endpoint = `${url}/admin/group/search-users`;
                const response = await axios.post(endpoint,
                    {
                        "groupId": groupId,
                        "query": query
                    },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token,
                            'Content-Type': 'application/json'
                        }
                    }
                );
                data = response.data;

            } else {
                const endpoint = `${url}/admin/group/users`;
                const response = await axios.post(endpoint,
                    {
                        "groupId": groupId,
                        query
                    },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token,
                            'Content-Type': 'application/json'
                        }
                    });
                data = response.data;
            }

            setUsersFromGroup(data.data);
        } catch (error) {
            setUsersFromGroup([]);
            console.error("GET USERS FROM GROUP ERROR", error);
            toast.error(error.response.data.error);
        } finally {
            setLoading(false);
        }
    }

    async function searchUserFromGroup(groupId, query) {
        await getUsersFromGroup(groupId, query);
    }


    async function addUserToGroup(groupId, users) {
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var res = await axios.post(`${url}/admin/group/adduser`,
                {
                    "groupId": groupId,
                    "users": users
                }, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }
            );
            await getUsersFromGroup(groupId);
            await getGroups();
            toast.success("Added !");
            return res.data;
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                toast.error(error.response.data.error);
            }
            if (error.response.status === 401) {
                toast.error("Session Expired");
                logout();
            }
            throw error;
        }
    }

    async function deleteUserFromGroup(groupId, user_id) {
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            await axios.delete(`${url}/admin/group/user/delete`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        "groupId": groupId,
                        "user_id": user_id
                    },
                }

            );
            await getUsersFromGroup(groupId);
            await getGroups();
            toast.success("DELETED Successfully !");
        } catch (error) {
            console.error(error);
            return "err";
        }
    }

    async function getActiveUsers(query = "") {
        setLoading(true);
        try {
            let data;
            if (query) {
                const endpoint = `${url}/admin/group/users/search`;
                const response = await axios.post(endpoint, { query }, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    }
                });
                data = response.data;
            } else {
                const endpoint = `${url}/admin/active-users`;
                const response = await axios.get(endpoint, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    }
                });
                data = response.data;
            }

            setActiveUser(data.data);
        } catch (error) {
            console.error("GET USERS ERROR", error);
            toast.error(error.response.data.message);
        } finally {
            setLoading(false);
        }
    }

    async function searchUser(query) {
        await getActiveUsers(query);
    }

    async function deleteGroup(groupId) {
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            await axios.delete(`${url}/admin/group/delete`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        "groupId": groupId,
                    },
                }

            );
            await getGroups();
            toast.success("DELETED Successfully !");
        } catch (error) {
            console.error(error);
            return "err";
        }
    }

    useEffect(() => {
        getGroups();
        getActiveUsers();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            loading,
            groups,
            activeUsers,
            addGroup,
            deleteGroup,
            getUsersFromGroup,
            usersFromGroup,
            addUserToGroup,
            deleteUserFromGroup,
            searchUser,
            searchUserFromGroup,
            searchGropName,
        }),
        [
            logged,
            loading,
            groups,
            activeUsers,
            addGroup,
            deleteGroup,
            getUsersFromGroup,
            usersFromGroup,
            addUserToGroup,
            deleteUserFromGroup,
            searchUser,
            searchUserFromGroup,
            searchGropName
        ] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Provide the authentication context to the children components
    return (
        <NotificationGroupContext.Provider value={contextValue}>{children}</NotificationGroupContext.Provider>
    );
};

export const useNotificationGroup = () => {
    return useContext(NotificationGroupContext);
};

export default NotificationGroupProvider;

