import React, { useEffect, useState } from 'react';
import {
    Td, Text
} from '@chakra-ui/react';

export default function UserLocation({ location }) {
    const [placeName, setPlaceName] = useState('Fetching location...');

    useEffect(() => {
        const fetchLocation = async () => {
            if (location && location.coordinates) {
                const [longitude, latitude] = location.coordinates; // Extract lat, lon
                try {
                    const fetchedPlaceName = await getPlaceName(latitude, longitude);
                    setPlaceName(fetchedPlaceName);
                } catch (error) {
                    console.error("Error fetching location:", error);
                    setPlaceName("Unknown location");
                }
            } else {
                setPlaceName("No location available");
            }
        };

        fetchLocation();
    }, [location]);

    async function getPlaceName(latitude, longitude) {
        try {
            const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`, {
                headers: {
                    'Accept-Language': 'en-US,en;q=0.9'
                }
            });
            if (!response.ok) {
                throw new Error(`Error ${response.status}: ${response.statusText}`);
            }
            const data = await response.json();
            return data.display_name || "Location not found";
        } catch (error) {
            // console.error("Error fetching place name:", error);
            return "Unknown location";
        }
    }

    return (
        <Td w={'500px'}>
            <Text
                isTruncated
                maxW="450px"
                whiteSpace="wrap"
                overflow="hidden"
            >
                {placeName}
            </Text>
        </Td>
    );
};

