import React, { useEffect, useRef, useState } from 'react';
import {
    Container,
    Text,
    SimpleGrid,
    VStack,
    Button,
    Stack,
    FormControl,
    Input,
    InputGroup,
    InputLeftElement,
    Textarea,
    Divider,
    Box,
    Image,
    useDisclosure
} from '@chakra-ui/react';
import { useFormik } from "formik";
import { FaRegUserCircle } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { BiPhoneCall } from "react-icons/bi";
import { BiSolidLock } from "react-icons/bi";
import { Link } from 'react-router-dom';
import { useAuth } from '../../../providers/AuthProvider';
import { GoPencil } from "react-icons/go";
import { CiImageOn } from "react-icons/ci";
import { useAdminMembers } from "../../../providers/AdminProvider";
import { toast } from "react-toastify";
import { ConfirmDialog } from '../../utils/ConfirmDialog';
import 'react-image-picker/dist/index.css';

export default function YourProfile() {
    const { updateAdminMember, deleteAdmin } = useAdminMembers();
    const { user, logout, updateLocaluser } = useAuth();

    const [profileImageSrc1, setProfileCatImageSrc1] = useState(null);
    const [profileImageSrc, setProfileCatImageSrc] = useState(null);
    const fileInputRef = useRef(null);

    const { onClose } = useDisclosure();
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(-1);

    const handleConfirm = async () => {
        try {
            const res = await deleteAdmin(selectedId);
            setIsConfirmDialogOpen(false);
            if (res) {
                logout();
            }

        } catch (error) {
            console.error(error);
        } finally {
            onClose();
        }
    };
    const handleClose = () => {
        setIsConfirmDialogOpen(false);
    };

    const formik = useFormik({
        initialValues: {
            f_name: user.first_name || "",
            l_name: user.last_name || "",
            email: user.email || "",
            phone: user.phone_number || "",
            bio: user.bio || "",
            new_password: "",
            re_enter_new_password: "",
        },
        onSubmit: async (values) => {
            let phone = values.phone;

            // Check if phone is a string or convert it to a string
            if (typeof phone !== 'string') {
                phone = String(phone);
            }
            phone = phone.trim();

            if (values.phone !== "" && phone.length !== 10) {
                return toast.error("Phone number must be 10 digits!");
            }

            if (values.new_password !== "" && values.new_password !== values.re_enter_new_password) {
                return toast.error("Password not matched!");
            }

            try {
                let res = await updateAdminMember(user._id, values.f_name, values.l_name, values.bio, values.phone, values.new_password, profileImageSrc);
                console.log(res.data);
                updateLocaluser(res.data);
            } catch (error) {

            }

        }
    });

    const handleIconClick = () => {
        fileInputRef.current.click();
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfileCatImageSrc1(reader.result);
            };
            reader.readAsDataURL(file);
        }
        setProfileCatImageSrc(event.target.files[0]);
    }

    return (
        <>
            <Container maxWidth='9xl' px={6}>
                <Text className='custom' fontSize="lg" fontWeight="500" color="primary.800">
                    <Link to='/settings'>
                        Settings &gt;{' '}
                    </Link>
                    <Text as="span" color="black" pl={2}>
                        Your Profile
                    </Text>
                </Text>

                <Stack direction={{ base: 'column', md: 'row' }} mt={8} w={'100%'} spacing={12}>
                    <VStack spacing={0} ml={{ md: 16 }}>
                        <Box position="relative" w={180} h={180} borderRadius={'50%'} display={'flex'} alignItems={'center'} justifyContent={'center'} border={'1px'} borderColor={'grey'}>
                            {profileImageSrc1 ? (
                                <Image src={profileImageSrc1} w="100%" h="100%" objectFit="cover" borderRadius="50%" />
                            ) : user.profile_picture ?
                                (<Image src={user.profile_picture} w="100%" h="100%" objectFit="cover" borderRadius="50%" />)
                                : (
                                    <CiImageOn color='grey' size={45} />
                                )}
                            <Box
                                position="absolute"
                                bottom={1}
                                right={2}
                                bg={"primary.800"}
                                w={12}
                                h={12}
                                borderRadius={'50%'}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                color={'white'}
                                onClick={handleIconClick}
                                cursor="pointer"
                            >
                                <GoPencil size={22} />
                            </Box>
                            <input
                                type="file"
                                name="img"
                                accept="image/png, image/jpg, image/jpeg"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </Box>
                        <Text className='custom' mt={2} fontSize="2xl" fontWeight="400" color="primary.800">
                            {user.first_name} {user.last_name}
                        </Text>
                        <Text fontSize="md" fontWeight={500}>{user.email}</Text>
                    </VStack>

                    <form onSubmit={formik.handleSubmit}>
                        <VStack w={'100%'} alignItems={'start'} ml={{ md: 12 }}>
                            <Text pb={1} fontWeight={'500'}>Your Details</Text>
                            <SimpleGrid w={'100%'} columns={{ base: 1, lg: 2 }} spacing={3}>
                                <FormControl>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={<FaRegUserCircle color="#F51E9A" size={'18px'} />}
                                            mt={2}
                                        />
                                        <Input
                                            id="f_name"
                                            name="f_name"
                                            type="text"
                                            variant="filled"
                                            placeholder="First Name"
                                            fontSize="md"
                                            py={6}
                                            px={10}
                                            bg="none"
                                            borderColor="gray.100"
                                            _focus={{
                                                borderColor: "primary.800",
                                            }}
                                            borderRadius="10"
                                            value={formik.values.f_name}
                                            onChange={formik.handleChange}
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={<FaRegUserCircle color="#F51E9A" size={'18px'} />}
                                            mt={2}
                                        />
                                        <Input
                                            id="l_name"
                                            name="l_name"
                                            type="text"
                                            variant="filled"
                                            placeholder="Last Name"
                                            fontSize="md"
                                            py={6}
                                            px={10}
                                            bg="none"
                                            borderColor="gray.100"
                                            _focus={{
                                                borderColor: "primary.800",
                                            }}
                                            borderRadius="10"
                                            value={formik.values.l_name}
                                            onChange={formik.handleChange}
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={<IoIosMail color="#F51E9A" size={'18px'} />}
                                            mt={2}
                                        />
                                        <Input
                                            id="email"
                                            name="email"
                                            type="email"
                                            variant="filled"
                                            placeholder="Email ID"
                                            fontSize="md"
                                            py={6}
                                            px={10}
                                            bg="none"
                                            borderColor="gray.100"
                                            _focus={{
                                                borderColor: "primary.800",
                                            }}
                                            borderRadius="10"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            isDisabled
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={<BiPhoneCall color="#F51E9A" size={'18px'} />}
                                            mt={2}
                                        />
                                        <Input
                                            type="tel" // Use type="tel" for phone numbers
                                            inputMode="numeric" // Set input mode to numeric to enforce numeric keyboard on mobile devices
                                            id="phone"
                                            name="phone"
                                            // type="number"
                                            variant="filled"
                                            placeholder="Phone Number"
                                            fontSize="md"
                                            py={6}
                                            px={10}
                                            bg="none"
                                            borderColor="gray.100"
                                            _focus={{
                                                borderColor: "primary.800",
                                            }}
                                            borderRadius="10"
                                            value={formik.values.phone}
                                            onChange={formik.handleChange}
                                        />
                                    </InputGroup>
                                </FormControl>
                            </SimpleGrid>

                            <FormControl mt={2}>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<FaRegUserCircle color="#F51E9A" size={'18px'} />}
                                        mt={2}
                                    />
                                    <Textarea
                                        id="bio"
                                        name="bio"
                                        type="text"
                                        variant="filled"
                                        placeholder="Your Bio"
                                        fontSize="md"
                                        py={4}
                                        px={10}
                                        bg="none"
                                        borderColor="gray.100"
                                        _focus={{
                                            borderColor: "primary.800",
                                        }}
                                        borderRadius="10"
                                        value={formik.values.bio}
                                        onChange={formik.handleChange}
                                    />
                                </InputGroup>
                            </FormControl>

                            <Text mt={8} pb={1} fontWeight={'500'}>Change Password</Text>

                            <Stack direction={{ base: 'column', md: 'row' }}>
                                <FormControl>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={<BiSolidLock color="#F51E9A" size={'18px'} />}
                                            mt={2}
                                        />
                                        <Input
                                            id="new_password"
                                            name="new_password"
                                            type="password"
                                            variant="filled"
                                            placeholder="New Password"
                                            fontSize="md"
                                            py={6}
                                            px={10}
                                            bg="none"
                                            borderColor="gray.100"
                                            _focus={{
                                                borderColor: "primary.800",
                                            }}
                                            borderRadius="10"
                                            value={formik.values.new_password}
                                            onChange={formik.handleChange}
                                        />
                                    </InputGroup>
                                </FormControl>

                                <FormControl>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={<BiSolidLock color="#F51E9A" size={'18px'} />}
                                            mt={2}
                                        />
                                        <Input
                                            id="re_enter_new_password"
                                            name="re_enter_new_password"
                                            type="password"
                                            variant="filled"
                                            placeholder="Re-Enter New Password"
                                            fontSize="md"
                                            py={6}
                                            px={10}
                                            bg="none"
                                            borderColor="gray.100"
                                            _focus={{
                                                borderColor: "primary.800",
                                            }}
                                            borderRadius="10"
                                            value={formik.values.re_enter_new_password}
                                            onChange={formik.handleChange}
                                        />
                                    </InputGroup>
                                </FormControl>
                            </Stack>

                            <Container mb={4}>
                                <Button
                                    mt={6}
                                    type="submit"
                                    bg="secondary"
                                    py='6'
                                    width="full"
                                    borderRadius={'50'}
                                    fontSize={'sm'}
                                >
                                    Save Your Profile
                                </Button>
                            </Container>

                            <Divider />

                            <Link onClick={() => { setSelectedId(user._id); setIsConfirmDialogOpen(true); }}>
                                <Text color={'red'} fontSize={'sm'} pb={{ base: 4, md: 0 }}>Delete Account? </Text>
                            </Link>

                        </VStack>
                    </form>
                </Stack>

                <ConfirmDialog
                    isOpen={isConfirmDialogOpen}
                    onClose={handleClose}
                    onConfirm={handleConfirm}
                    title="Are you sure?"
                    body="This action cannot be undone."
                />
            </Container>
        </>
    )
}
