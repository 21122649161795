import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = extendTheme({
  components: {
    Button: {
      // 1. We can update the base styles
      baseStyle: {
        borderRadius: 'lg',
      },
    },
  },
  colors: {
    "primary": {
      "50": "#fef5fa",
      "100": "#fad6eb",
      "200": "#f5b2d8",
      "300": "#ef81c0",
      "400": "#d773ac",
      "500": "#b66191",
      "600": "#99527b",
      "700": "#F51E9A",
      "800": "#F51E9A",
      "900": "#D3007E"
    },
    "secondary": "#FFCC18",
    "grey": {
      "50": "#fef5fa",
      "100": "#e3e3e3",
      "200":"#5E5E5E",
      "300":"#4B4B4B",
    },
  },
  styles: {
    global: {
      'html, body': {
        bg: 'white',
      },
    },
  },
})

// removing logs in prod 
if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);

